import React from "react";
import classNames from "classnames";

import styles from "./Scrollable.module.scss";

const Scrollable = React.forwardRef(({ className, children, style }, ref) => (
  <div
    className={classNames(styles.Scrollable, className)}
    style={style}
    ref={ref}
  >
    {children}
  </div>
));

export default Scrollable;

import React from "react";
import { StatusColorsByLabel } from "../../constants/statuses";
import { getFontContrast } from "../../utils";
import styles from "./Pill.module.scss";

function Pill({
  color = "#FFFFFF", // TODO: Replace with @value from Scss
  children,
  ...props
}) {
  // The To Do colour matches the table background, so for here we swap it out
  // for a semi-transparent grey so that it's visible
  const backgroundColor =
    color === StatusColorsByLabel["To Do"] ? "rgba(22, 22, 22, 0.1)" : color;
  return (
    <span
      className={styles.Pill}
      style={{
        backgroundColor,
        color: getFontContrast(color),
      }}
      {...props}
    >
      {children}
    </span>
  );
}

export default Pill;

import React, { useMemo } from "react";

import useCycleBlockedIssuesQuery from "../../graphql/hooks/useCycleBlockedIssuesQuery";
import { buildLinearLink } from "../../utils/buildLinearLink";

import Assignee from "../Assignee/Assignee";
import Container from "../Container/Container";
import Table from "../Table/Table";

const COLUMNS = [
  {
    id: "Platform",
    children: "Platform",
  },
  {
    id: "ID",
    children: "ID",
  },
  {
    id: "Summary",
    children: "Summary",
  },
  {
    id: "Epic",
    children: "Epic",
  },
  {
    id: "Points",
    children: "Points",
    textAlign: "right",
  },
  {
    id: "Assignee",
    children: "Assignee",
  },
];

const BlockedTable = ({ cycleId, filters, ...props }) => {
  const { data, loading, error } = useCycleBlockedIssuesQuery(cycleId, filters);

  const rows = useMemo(() => {
    if (loading || !data) return null;

    const rows = data.blockedIssues.map((issue) => {
      const platformsCell = issue.platforms.map((p) => p.name).join(" ");

      const linkCell = buildLinearLink(data.cycle.team.key, issue.number);

      const assigneeCell = issue.assignee ? (
        <Assignee assignee={issue.assignee} />
      ) : null;

      return [
        platformsCell || null,
        linkCell,
        issue.title,
        issue.project?.name,
        issue.estimate || 0,
        assigneeCell,
      ];
    });

    return rows;
  }, [data, loading]);

  return (
    <Container
      title="Blocked"
      titleCount={rows?.length}
      error={error}
      loading={loading}
      empty={!rows?.length}
      emptyMessage="No blocked issues in this cycle"
      {...props}
    >
      {rows && <Table columns={COLUMNS} rows={rows} />}
    </Container>
  );
};

export default BlockedTable;

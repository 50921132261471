import { createContext, useState } from "react";

const MainHeadingContext = createContext();

function MainHeadingProvider({ children }) {
  const [title, setTitle] = useState("");

  return (
    <MainHeadingContext.Provider value={{ title, setTitle }}>
      {children}
    </MainHeadingContext.Provider>
  );
}

export { MainHeadingProvider, MainHeadingContext };

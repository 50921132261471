import { useContext, useEffect } from "react";

import { MainHeadingContext } from "../contexts/MainHeadingContext";

export default function useSetMainHeading(title) {
  const { setTitle } = useContext(MainHeadingContext);

  return useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);
}

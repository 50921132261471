import React, { useEffect, useState } from "react";

import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";

import { createShortName } from "../Assignee/Assignee";
import FilterFacet from "../FilterFacet/FilterFacet";
import CycleWorkdays from "../CycleWorkdays/CycleWorkdays";
import { sortObjectValueAlpha } from "../../utils/sortObjectValueAlpha";

import FilterSidebar from "../FilterSidebar/FilterSidebar";
import ReportFilters from "../ReportFilters/ReportFilters";

function ActiveReportSidebar({
  teams = [],
  cycles = [],
  team,
  cycle,
  filters,
  onFiltersChange,
}) {
  const teamId = team?.id;
  const { data: cycleData } = useTeamCycleQuery(teamId);

  const [filterOptions, setFilterOptions] = useState({
    platforms: [],
    assignees: [],
  });

  const handleFiltersChanged = (filter, options) => {
    const filterChecked = (options) =>
      options.filter(({ checked }) => checked).map(({ value }) => value);

    const checkedOptions = filterChecked(filterOptions[filter]);

    setFilterOptions((f) => {
      f[filter] = options;
      return f;
    });

    onFiltersChange({
      [filter]: checkedOptions, // override checked options.
    });
  };

  useEffect(() => {
    if (!cycleData) return;

    // build out facet options for platforms.
    const sortedPlatformOptions = cycleData.team.platforms
      .map(({ name }) => ({
        value: name,
        label: name,
        checked: filters.platforms.includes(name),
      }))
      .sort(sortObjectValueAlpha("label"));

    // build out facet options for assignees.
    const sortedAssigneeOptions = cycleData.team.assignees
      .map(({ id, name }) => ({
        value: id,
        label: createShortName(name, true),
        checked: filters.assignees.includes(id),
      }))
      .sort(sortObjectValueAlpha("label"));

    setFilterOptions({
      assignees: sortedAssigneeOptions,
      platforms: sortedPlatformOptions,
    });
  }, [cycleData, filters]);

  return (
    <FilterSidebar>
      <ReportFilters
        team={team}
        cycle={cycle}
        teams={teams}
        cycles={cycles}
        report="active"
      />

      {cycle && (
        <>
          <CycleWorkdays startDate={cycle.startsAt} endDate={cycle.endsAt} />

          <FilterFacet
            options={filterOptions.platforms}
            onChange={(options) => handleFiltersChanged("platforms", options)}
            label="Platforms"
          />
          <FilterFacet
            options={filterOptions.assignees}
            onChange={(options) => handleFiltersChanged("assignees", options)}
            label="Assignee"
          />
        </>
      )}
    </FilterSidebar>
  );
}

export default ActiveReportSidebar;

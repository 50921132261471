import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import workdayCount from "../../utils/workdayCount";

import styles from "./CycleWorkdays.module.scss";

const CycleWorkdays = ({
  startDate,
  endDate,
  format = "ddd MMM D",
  showTotalDays,
}) => {
  const [now, setNow] = useState(dayjs.tz());
  useEffect(() => {
    // Update "now" every minute
    const handle = setTimeout(() => setNow(dayjs.tz()), 60 * 1000);
    return () => clearTimeout(handle);
  }, [now]);

  const workdays = useMemo(() => workdayCount(now, endDate), [now, endDate]);
  const totalDays = useMemo(
    () => workdayCount(startDate, endDate),
    [startDate, endDate]
  );

  const startDateFormat = dayjs.tz(startDate).format(format);
  const endDateFormat = dayjs.tz(endDate).format(format);

  const dateRangeLabel = `Start date ${startDateFormat}, end date ${endDateFormat}`;
  let daysText = `${workdays} weekday${workdays !== 1 && "s"} left`;

  if (showTotalDays) {
    daysText = `${totalDays} day${totalDays !== 1 && "s"}`;
  }

  return (
    <>
      <div className={styles.CycleDates} aria-label={dateRangeLabel}>
        <span>{startDateFormat} – </span>
        <span>{endDateFormat}</span>
      </div>

      <span className={styles.CycleDaysLeft}>{daysText}</span>
    </>
  );
};

export default CycleWorkdays;

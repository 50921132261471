import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dateLoop from "./dateLoop";

dayjs.extend(weekOfYear);

/**
 * Builds out an array of weeks with workday by key elements.
 * @export
 * @param {*} startDate
 * @param {*} endDate
 */
export default function generateWeeks(startDate, endDate, flat = false) {
  const weeks = {};

  if (!endDate) {
    endDate = startDate;
  }

  dateLoop(
    dayjs.tz(startDate).startOf("week"),
    dayjs.tz(endDate).endOf("week"),
    (currentDay) => {
      const currentWeek = currentDay.week();

      if (!weeks[currentWeek]) weeks[currentWeek] = {};

      weeks[currentWeek][currentDay.format("YYYY-MM-DD")] = [];
    },
    {
      skipWeekendDays: true,
    }
  );

  const result = Object.keys(weeks).map((k) => weeks[k]);

  if (flat) {
    const flatMap = result.reduce((m, current) => {
      m = { ...m, ...current };
      return m;
    }, {});
    return flatMap;
  }

  return result;
}

import React, { useMemo } from "react";

import {
  completeStates,
  developmentStates,
  reviewStates,
} from "../../constants/statuses";
import useCycleEstimatesQuery from "../../graphql/hooks/useCycleEstimatesQuery";
import { useWorkflowStates } from "../../graphql/hooks/useWorkflowStatesQuery";
import { mapStateToProgress } from "../../utils/mapStateToProgress";
import sumObjectValuesByKey from "../../utils/sumObjectValuesByKey";

import Container from "../Container/Container";
import ProgressChart from "../ProgressChart";

import styles from "./FinalTicketStatus.module.scss";

function FinalTicketStatus({ cycleId, filters, ...props }) {
  const {
    data: cycleEstimates,
    loading,
    error,
  } = useCycleEstimatesQuery(cycleId, filters);
  const { workflowStatesById } = useWorkflowStates(cycleId);

  const estimatesByState = useMemo(() => {
    if (!cycleEstimates) return {};
    const estimatesByState = {};
    for (const { state, estimate } of cycleEstimates.cycle.cycleEstimates) {
      estimatesByState[state] = estimate;
    }
    return estimatesByState;
  }, [cycleEstimates]);

  // The following values are not memoized since they loop over arrays of less
  // than 10 elements: code simplicity is preferred over premature optimisation
  const developmentSegments = developmentStates.map(
    mapStateToProgress(estimatesByState, workflowStatesById)
  );
  const reviewSegments = reviewStates.map(
    mapStateToProgress(estimatesByState, workflowStatesById)
  );
  const completedSegments = completeStates.map(
    mapStateToProgress(estimatesByState, workflowStatesById)
  );

  const developmentTotal = sumObjectValuesByKey(developmentSegments, "value");
  const reviewTotal = sumObjectValuesByKey(reviewSegments, "value");
  const completedTotal = sumObjectValuesByKey(completedSegments, "value");
  const totalPoints = developmentTotal + reviewTotal + completedTotal;

  return (
    <Container
      title="Final Ticket Status"
      titleCount={`${completedTotal}/${totalPoints}`}
      loading={loading}
      error={error}
      empty={!cycleEstimates?.cycle?.cycleEstimates?.length}
      emptyMessage="No progress has been made in this cycle"
      {...props}
    >
      <div className={styles.ChartContainer}>
        <ProgressChart
          label={`${developmentTotal} points in development`}
          segments={developmentSegments}
        />
        <ProgressChart
          label={`${reviewTotal} points in QA`}
          segments={reviewSegments}
        />
        <ProgressChart
          label={`${completedTotal} points completed`}
          segments={completedSegments}
        />
      </div>
      <div className={styles.TotalPoints}>{totalPoints} points committed</div>
    </Container>
  );
}

export default FinalTicketStatus;

import React, { useEffect, useState } from "react";

import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";

import FilterFacet from "../FilterFacet/FilterFacet";
import CycleWorkdays from "../CycleWorkdays/CycleWorkdays";
import { sortObjectValueAlpha } from "../../utils/sortObjectValueAlpha";

import FilterSidebar from "../FilterSidebar/FilterSidebar";
import ReportFilters from "../ReportFilters/ReportFilters";
import Button from "../Button/Button";

function SprintEndReportSidebar({
  teams = [],
  cycles = [],
  team,
  cycle,
  filters,
  onFiltersChange,
}) {
  const teamId = team?.id;
  const { data: cycleData } = useTeamCycleQuery(teamId);

  const [filterOptions, setFilterOptions] = useState({
    platforms: [],
  });

  const handleFiltersChanged = (filter, options) => {
    const filterChecked = (options) =>
      options.filter(({ checked }) => checked).map(({ value }) => value);

    const checkedOptions = filterChecked(filterOptions[filter]);

    setFilterOptions((f) => {
      f[filter] = options;
      return f;
    });

    onFiltersChange({
      [filter]: checkedOptions, // override checked options.
    });
  };

  const handleExportPDF = () => {
    window.print();
  };

  useEffect(() => {
    if (!cycleData) return;

    // build out facet options for platforms.
    const sortedPlatformOptions = cycleData.team.platforms
      .map(({ name }) => ({
        value: name,
        label: name,
        checked: filters.platforms.includes(name),
      }))
      .sort(sortObjectValueAlpha("label"));

    setFilterOptions({
      platforms: sortedPlatformOptions,
    });
  }, [cycleData, filters]);

  return (
    <FilterSidebar>
      <ReportFilters
        team={team}
        cycle={cycle}
        teams={teams}
        cycles={cycles}
        report="sprint-end"
      />

      {cycle && (
        <>
          <CycleWorkdays
            startDate={cycle.startsAt}
            endDate={cycle.endsAt}
            showTotalDays
          />

          <FilterFacet
            options={filterOptions.platforms}
            onChange={(options) => handleFiltersChanged("platforms", options)}
            label="Platforms"
          />
        </>
      )}

      <Button onClick={handleExportPDF}>Export PDF</Button>
    </FilterSidebar>
  );
}

export default SprintEndReportSidebar;

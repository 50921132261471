import React, { useState } from "react";
import { useHistory } from "react-router";

import AuthService from "../../services/auth.service";

import Fieldset from "../Fieldset/Fieldset";
import Button from "../Button/Button";
import Message from "../Message/Message";

import styles from "../Login/Login.module.scss";
import { validateEmail, validatePassword } from "./validation";

function Login() {
  const history = useHistory();
  const [values, setValues] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const [errors, setErrors] = useState({ email: "", password: "" });

  const checkEmail = (email) => {
    const error = validateEmail(email);

    setErrors({
      ...errors,
      email: error,
    });

    setValues({
      ...values,
      email,
    });

    return error;
  };

  const checkPassword = (password) => {
    const error = validatePassword(password);

    setErrors({
      ...errors,
      password: error,
    });

    setValues({
      ...values,
      password,
    });

    return error;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (checkEmail(values.email) || checkPassword(values.password)) {
      return;
    }

    setLoading(true);

    try {
      await AuthService.login(values.email, values.password);
      history.push("/");
    } catch (err) {
      console.error(err);
      setError(`Sorry, wrong email and password.`);
    }

    setLoading(false);
  };

  return (
    <form className={styles.Form} onSubmit={handleFormSubmit} noValidate>
      <Fieldset
        className={styles.Input}
        id="email"
        name="email"
        value={values.email}
        text="Email Address"
        type="email"
        required
        placeholder="Enter your email"
        onChange={(e) => checkEmail(e.target.value)}
        onInput={() => setError("")}
      />
      {errors.email && (
        <Message className={styles.Message} error>
          {errors.email}
        </Message>
      )}

      <Fieldset
        className={styles.Input}
        id="password"
        name="password"
        value={values.password}
        text="Password"
        type="password"
        placeholder="Enter your password"
        onChange={(e) => checkPassword(e.target.value)}
        onInput={() => setError("")}
      />
      {errors.password && (
        <Message className={styles.Message} error>
          {errors.password}
        </Message>
      )}

      <Button className={styles.Submit} type="submit" loading={loading}>
        Log In
      </Button>

      {error && (
        <Message className={styles.Message} error>
          {error}
        </Message>
      )}
    </form>
  );
}
export default Login;

import React, { useCallback, useMemo } from "react";

import Checkbox from "../Checkbox/Checkbox";
import Count from "../Count/Count";

import styles from "./FilterFacet.module.scss";

const FilterFacet = ({ options, label, showCount, onChange }) => {
  const handleChecked = useCallback(
    (value) => {
      const newOptions = [...options];
      let index = null;

      newOptions.forEach((option, i) => {
        if (option.value === value) {
          index = i;
        }
      });

      newOptions[index].checked = !newOptions[index].checked;

      onChange(newOptions);
    },
    [options, onChange]
  );

  const count = useMemo(() => {
    return options.filter((option) => option.checked).length;
  }, [options]);

  return (
    <fieldset className={styles.FilterFacet}>
      <legend className={styles.FilterFacetLegend}>
        {label} {showCount && <Count>{count}</Count>}
      </legend>
      <div className={styles.FacetOptions}>
        {options.map((option) => (
          <Checkbox
            key={option.value}
            value={option.value}
            checked={option.checked}
            onChange={() => handleChecked(option.value)}
          >
            {option.label}
          </Checkbox>
        ))}
      </div>
    </fieldset>
  );
};

export default FilterFacet;

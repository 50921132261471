import React, { useCallback, useMemo, useState } from "react";

import useCycleIssuesQuery from "../../graphql/hooks/useCycleIssuesQuery";
import { useWorkflowTable } from "../../graphql/hooks/useWorkflowStatesQuery";
import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";
import { Types } from "../../constants/types";
import { sortObjectValueAlpha } from "../../utils/sortObjectValueAlpha";

import { ReactComponent as IconChevron } from "../../assets/images/chevron.svg";

import Assignee from "../Assignee/Assignee";
import Container from "../Container/Container";
import Table from "../Table/Table";

import styles from "./IssueStatusTable.module.scss";

const specialTypes = Object.values(Types);

// Default shape of zeroed out totals.
const defaultTotals = (workflowStatesTemplate) => {
  return {
    total: 0,
    counts: { ...workflowStatesTemplate },
    subtotals: specialTypes.reduce((subtotals, type) => {
      subtotals[type] = {
        type: type,
        counts: { ...workflowStatesTemplate },
        total: 0,
      };
      return subtotals;
    }, {}),
  };
};

// Generic total counter based on a grouping by
// record type.
const countTotals = (
  mapRef,
  record,
  recordName,
  issue,
  aggregation,
  workflowStatesTemplate
) => {
  const recordId = record?.id;

  if (!recordId) return mapRef;

  // Initialize record totals.
  if (!mapRef[recordId]) {
    mapRef[recordId] = {
      ...defaultTotals(workflowStatesTemplate),
      [recordName]: record,
    };
  }

  if (aggregation === "count") {
    // count issues
    mapRef[recordId].counts[issue.state.id] += 1;
    mapRef[recordId].total += 1;
  } else if (aggregation === "sum") {
    // sum estimates
    mapRef[recordId].counts[issue.state.id] += issue.estimate;
    mapRef[recordId].total += issue.estimate;
  }

  // Count subtotals by special labels.
  specialTypes.forEach((type) => {
    const labels = issue.labels.map(({ name }) => name);
    if (labels.includes(type)) {
      if (aggregation === "sum") {
        mapRef[recordId].subtotals[type].counts[issue.state.id] +=
          issue.estimate;
        mapRef[recordId].subtotals[type].total += issue.estimate;
      } else if (aggregation === "count") {
        mapRef[recordId].subtotals[type].counts[issue.state.id] += 1;
        mapRef[recordId].subtotals[type].total += 1;
      }
    }
  });

  return mapRef;
};

const totalByAssigneeState = (issues, aggregation, workflowStatesTemplate) => {
  const peopleById = {};

  issues.forEach((issue) => {
    countTotals(
      peopleById,
      issue?.assignee,
      "assignee",
      issue,
      aggregation,
      workflowStatesTemplate
    );
  });

  return peopleById;
};

const totalByLabelState = (issues, aggregation, workflowStatesTemplate) => {
  // Generate counts for all labels
  const labelsById = {};

  issues.forEach((issue) => {
    issue.labels.forEach((label) => {
      countTotals(
        labelsById,
        label,
        "label",
        issue,
        aggregation,
        workflowStatesTemplate
      );
    });
  });

  return labelsById;
};

const buildTypeRow = (typeTotals, stateColumns) => {
  const typeCounts = stateColumns.map((column) => {
    return typeTotals?.counts[column.id] || 0;
  });

  return [
    <div className={styles.TypeCell}>{typeTotals.type}</div>,
    ...typeCounts,
    typeTotals?.total || 0,
  ];
};

// Generic Expandable Row settings.
const buildExpandRow = (
  descriptorComponent,
  descriptorCellStyle,
  totals,
  stateColumns,
  isExpanded,
  toggleExpanded
) => {
  const descriptorCell = (
    <div className={descriptorCellStyle}>
      {descriptorComponent}

      <button className={styles.ExpandToggle} onClick={toggleExpanded}>
        {isExpanded ? (
          <IconChevron className={styles.ChevronUp} />
        ) : (
          <IconChevron className={styles.ChevronDown} />
        )}
      </button>
    </div>
  );

  const dynamicValues = stateColumns.map((column) => {
    return totals.counts[column.id] || 0;
  });

  return [descriptorCell, ...dynamicValues, totals.total];
};

// Custom Assignee row with Assignee display cell
const buildAssigneeRow = (totals, stateColumns, isExpanded, toggleExpanded) => {
  const assigneeCell = <Assignee assignee={totals.assignee} noAvatar />;
  return buildExpandRow(
    assigneeCell,
    styles.AssigneeCell,
    totals,
    stateColumns,
    isExpanded,
    () => toggleExpanded(totals.assignee.id)
  );
};

// Custom Platform row with label and styling.
const buildPlatformRow = (totals, stateColumns, isExpanded, toggleExpanded) => {
  return buildExpandRow(
    totals.label.name,
    styles.PlatformCell,
    totals,
    stateColumns,
    isExpanded,
    () => {
      toggleExpanded(totals.label.id);
    }
  );
};

function IssueStatusTable({
  title,
  teamId,
  cycleId,
  filters,
  aggregation = "count",
  ...props
}) {
  const { data: cycleData } = useTeamCycleQuery(teamId);
  const { data, loading, error } = useCycleIssuesQuery(cycleId, filters);
  const { stateColumns, workflowStatesTemplate } = useWorkflowTable(cycleId);
  const [expandedAssignees, setExpandedAssignees] = useState({});
  const [expandedPlatforms, setExpandedPlatforms] = useState({});

  const toggleAssigneeExpanded = useCallback(
    (assigneeId) => {
      const data = { ...expandedAssignees };
      data[assigneeId] = !data[assigneeId];
      setExpandedAssignees(data);
    },
    [expandedAssignees]
  );

  const togglePlatformExpanded = useCallback(
    (platformId) => {
      const data = { ...expandedPlatforms };
      data[platformId] = !data[platformId];
      setExpandedPlatforms(data);
    },
    [expandedPlatforms]
  );

  const { platformRows, assigneeRows } = useMemo(() => {
    if (loading || !data) return {};

    const issues = data.cycle.issues;
    const peopleById = totalByAssigneeState(
      issues,
      aggregation,
      workflowStatesTemplate
    );
    const labelsById = totalByLabelState(
      issues,
      aggregation,
      workflowStatesTemplate
    );

    const allPlatforms = cycleData?.team?.platforms || [];

    // If there are any selected Platform label filters, use those.
    // Otherwise fallback to all platform labels.
    const filteredPlatforms =
      filters.platforms.length > 0
        ? allPlatforms.filter((platform) =>
            filters.platforms.includes(platform.name)
          )
        : allPlatforms;

    // Pick out the platform labels from all label totals.
    const platformsById = filteredPlatforms.reduce((platforms, platform) => {
      if (labelsById[platform.id]) {
        platforms[platform.id] = labelsById[platform.id];
      }
      return platforms;
    }, {});

    // Sort rows by the record name
    const platformRows = Object.values(platformsById).sort((a, b) =>
      sortObjectValueAlpha("name", "asc")(a.label, b.label)
    );
    const assigneeRows = Object.values(peopleById).sort((a, b) =>
      sortObjectValueAlpha("name", "asc")(a.assignee, b.assignee)
    );

    return {
      platformRows,
      assigneeRows,
    };
  }, [data, loading, aggregation, workflowStatesTemplate, filters, cycleData]);

  const { platformTableRows, platformColumns, platformTotalCount } =
    useMemo(() => {
      const columns = [
        {
          id: "1",
          children: "Platform",
          width: "10%",
        },
        ...stateColumns,
        {
          id: "2",
          children: "Total",
          textAlign: "right",
        },
      ];
      const rows = [];

      if (platformRows) {
        for (let platformRow of platformRows) {
          const isRowExpanded = expandedPlatforms[platformRow.label.id];

          rows.push(
            buildPlatformRow(
              platformRow,
              stateColumns,
              isRowExpanded,
              togglePlatformExpanded
            )
          );

          if (isRowExpanded) {
            // push sub totals.
            Object.values(platformRow.subtotals).forEach((subtotalRow) => {
              rows.push(buildTypeRow(subtotalRow, stateColumns));
            });
          }
        }
      }

      const totalCount = data?.cycle?.issues?.reduce((total, issue) => {
        if (aggregation === "sum") {
          if (issue.estimate) {
            total += issue.estimate;
          }
        } else if (aggregation === "count") {
          total += 1;
        }

        return total;
      }, 0);

      return {
        platformTableRows: rows,
        platformColumns: columns,
        platformTotalCount: totalCount,
      };
    }, [
      aggregation,
      platformRows,
      stateColumns,
      expandedPlatforms,
      togglePlatformExpanded,
      data,
    ]);

  const { assigneeTableRows, assigneeColumns } = useMemo(() => {
    const columns = [
      {
        id: "1",
        children: "Assignee",
        width: "10%",
      },
      ...stateColumns,
      {
        id: "2",
        children: "Total",
        textAlign: "right",
      },
    ];
    const rows = [];

    if (assigneeRows) {
      for (let assigneeRow of assigneeRows) {
        const isRowExpanded = expandedAssignees[assigneeRow.assignee.id];

        // Push totals row
        rows.push(
          buildAssigneeRow(
            assigneeRow,
            stateColumns,
            isRowExpanded,
            toggleAssigneeExpanded
          )
        );

        if (isRowExpanded) {
          // Push subtotals rows.
          Object.values(assigneeRow.subtotals).forEach((subtotalRow) => {
            rows.push(buildTypeRow(subtotalRow, stateColumns));
          });
        }
      }
    }

    return {
      assigneeTableRows: rows,
      assigneeColumns: columns,
    };
  }, [assigneeRows, stateColumns, expandedAssignees, toggleAssigneeExpanded]);

  const isTableEmpty = !platformTableRows?.length && !assigneeTableRows?.length;

  return (
    <Container
      title={title}
      titleCount={platformTotalCount}
      loading={loading}
      error={error}
      empty={isTableEmpty}
      emptyMessage="No issues in this cycle"
    >
      {platformTableRows && (
        <Table
          className={styles.Table}
          columns={platformColumns}
          rows={platformTableRows}
        />
      )}
      {assigneeTableRows && (
        <Table
          className={styles.Table}
          columns={assigneeColumns}
          rows={assigneeTableRows}
        />
      )}
    </Container>
  );
}

export default IssueStatusTable;

import useCycleIssuesQuery from "./useCycleIssuesQuery";

export default function useCycleBlockedIssuesQuery(cycleId, filters) {
  const { data, ...others } = useCycleIssuesQuery(cycleId, filters);

  if (data) {
    // Filter out all issues that are blocked
    const blockedIssues = data.cycle.issues.filter((issue) => issue.blocked);

    return {
      data: {
        cycle: data.cycle,
        blockedIssues,
      },
      ...others,
    };
  }

  return {
    data,
    ...others,
  };
}

const NONBREAKING_HYPHEN = '\u2011';

/**
 * Create a Linear URL to the team issue.
 *
 * @param {string} teamKey
 * @param {number} issueNumber
 * @returns {JSX} A target="_blank" link to the Linear issue
 */
export const buildLinearLink = (teamKey, issueNumber) => {
  const url = `https://linear.app/fueled/issue/${teamKey}-${issueNumber}`;

  return (
    <a href={url} target="_blank" rel="noreferrer" title="View issue on Linear">
      {teamKey}{NONBREAKING_HYPHEN}{issueNumber}
    </a>
  );
};

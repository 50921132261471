import { useQuery, gql } from "@apollo/client";

const ALL_LINEAR_TEAMS = gql`
  query {
    teams {
      id
      name
      key
      modifiedAt
      workspace {
        lastSyncAt
      }
      activeCycle {
        id
        number
        startsAt
        endsAt
      }
      assignees {
        name
        email
      }
      cycles {
        id
      }
    }
  }
`;

export default function useTeamQuery() {
  return useQuery(ALL_LINEAR_TEAMS);
}

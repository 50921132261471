import axios from "axios";

import { API_URL, API_VERSION } from "../config";

let authToken = null;

export default function createApiClient() {
  return axios.create({
    baseURL: API_URL,
    headers: {
      Accept: `application/json; version=${API_VERSION}`,
      Authorization: authToken ? `Bearer ${authToken}` : "",
    },
  });
}

export function setAuthToken(token) {
  authToken = token;
}

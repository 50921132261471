import { useMemo } from "react";

import { Types } from "../../constants/types";
import useCycleIssuesQuery from "../../graphql/hooks/useCycleIssuesQuery";
import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";
import { filterByLabels } from "../../utils/filterByLabels";
import sumObjectValuesByKey from "../../utils/sumObjectValuesByKey";

import Container from "../Container/Container";
import Table from "../Table/Table";

const ScopeChangeOverview = ({ teamId, cycleId, filters }) => {
  const { data: teamCycleData } = useTeamCycleQuery(teamId);
  const { data: cycleData } = useCycleIssuesQuery(cycleId, filters);

  const columns = [
    {
      id: "Platform",
      children: "Platform",
    },
    {
      id: "Story",
      children: "Story Pts(#)",
    },
    {
      id: "Task",
      children: "Task Pts (#)",
    },
    {
      id: "Bug",
      children: "Bugs Pts(#)",
    },
    {
      id: "Change",
      children: "% Change",
    },
  ];

  const { totalChange, rows } = useMemo(() => {
    let existingPoints = 0;
    let addedPoints = 0;
    let totalChange = 0;

    let rows = [];

    if (cycleData) {
      const cycleStart = new Date(cycleData.cycle.startsAt);
      const beforeSprintIssues = cycleData.cycle.issues.filter(
        (issue) => new Date(issue.createdAt).valueOf() <= cycleStart.valueOf()
      );
      const afterSprintIssues = cycleData.cycle.issues.filter(
        (issue) => new Date(issue.createdAt).valueOf() > cycleStart.valueOf()
      );

      const platforms = [...teamCycleData.team.platforms];
      const specialTypes = [Types.STORY, Types.TASK, Types.BUG];

      existingPoints = sumObjectValuesByKey(beforeSprintIssues, "estimate");

      platforms.forEach((platform) => {
        // Row contains tuple for estimate and total tickets.
        // This will be converted to string label after calculations are complete.
        // [label, [estimate, count], [estimate, count], [estimate, count], change]
        let platformAfterEstimate = 0;
        const typeTuples = specialTypes.map((type) => {
          const typeIssues = filterByLabels(afterSprintIssues, [
            platform.name,
            type,
          ]);
          const estimate = sumObjectValuesByKey(typeIssues, "estimate");
          const total = typeIssues.length;
          addedPoints += estimate;
          platformAfterEstimate += estimate;
          return [estimate, total];
        });

        let percentChange = null;

        if (platformAfterEstimate) {
          percentChange = (
            (platformAfterEstimate / existingPoints) *
            100
          ).toFixed(1);
        }

        rows.push([
          platform.name,
          ...typeTuples.map(([estimate, total]) =>
            estimate ? `${estimate} (${total})` : null
          ), // percent change on new estimate
          percentChange > 0 ? `${percentChange}%` : null,
        ]);
      });

      const typeEstimates = specialTypes.map((type) => {
        const typeIssues = filterByLabels(afterSprintIssues, type);
        return sumObjectValuesByKey(typeIssues, "estimate");
      });

      if (existingPoints > 0) {
        // Handle invalid division
        totalChange = `${((addedPoints / existingPoints) * 100).toFixed(1)}%`;
      }

      rows.push(["Total", ...typeEstimates, totalChange]);
    }

    return {
      totalChange,
      rows,
    };
  }, [teamCycleData, cycleData]);

  return (
    <Container title="Scope Change Overview" titleCount={`+${totalChange}`}>
      <Table rows={rows} columns={columns} hasTotalRow />
    </Container>
  );
};

export default ScopeChangeOverview;

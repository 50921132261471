import dayjs from "dayjs";

/**
 * Given a start and end date, iterate over each day with a callback.
 * Option to skip weekends as a boolean to not callback on Saturday/Sunday.
 *
 * @export
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} callbackFn
 * @param {boolean} [options={
 *     skipWeekendDays: false,
 *   }]
 */
export default function dateLoop(
  startDate,
  endDate,
  callbackFn,
  options = {
    skipWeekendDays: false,
  }
) {
  if (!endDate) {
    endDate = startDate;
  }

  const SUNDAY = 0;
  const SATURDAY = 6;

  const start = dayjs.tz(startDate);
  const end = dayjs.tz(endDate);
  let currentDay = start.clone();

  // Loop through all dates and run the function.
  while (currentDay <= end) {
    const isWeekendDay = [SUNDAY, SATURDAY].includes(currentDay.day());

    if (!(options.skipWeekendDays && isWeekendDay)) {
      callbackFn(currentDay);
    }

    currentDay = currentDay.add(1, "day");
  }
}

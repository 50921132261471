import { useMemo } from "react";
import { useParams } from "react-router";

import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";
import useTeamQuery from "../../graphql/hooks/useTeamQuery";

import Spinner from "../Spinner/Spinner";

function TeamCycleResolver({ component: Component, error, ...rest }) {
  const { teamKey, cycleNumber } = useParams();

  // Trigger the GQL calls before rendering the dashboard.
  const teamQuery = useTeamQuery();
  const team = useMemo(
    () => teamQuery?.data?.teams?.find((t) => t.key === teamKey),
    [teamQuery, teamKey]
  );
  const teamCycleQuery = useTeamCycleQuery(team?.id);
  const cycle = useMemo(
    () =>
      teamCycleQuery?.data?.team?.cycles?.find(
        (c) => c.number === parseInt(cycleNumber)
      ),
    [teamCycleQuery, cycleNumber]
  );

  // Fallback to team active cycle if no cycle number provided.
  const currentCycle = useMemo(() => {
    return cycleNumber ? cycle : team?.activeCycle;
  }, [cycleNumber, cycle, team]);

  const ready = useMemo(() => {
    // Route params have changed. Await all relevant data.
    const teamLoaded = !!(!teamQuery.loading && teamQuery.data);
    const cycleLoaded = !!(!teamCycleQuery.loading && teamCycleQuery.data);
    let canLoad = false;

    // check if cycle is required as well, user is looking for a specific cycle
    if (cycleNumber) {
      canLoad = teamLoaded && cycleLoaded;
    } else {
      canLoad = teamLoaded;
    }

    if (!teamKey && !cycleNumber) {
      canLoad = true;
    }

    return canLoad;
  }, [teamKey, teamQuery, teamCycleQuery, cycleNumber]);

  if (!ready) {
    return <Spinner />;
  }

  if (ready && teamKey && !team) {
    return error;
  }

  return <Component team={team} cycle={currentCycle} />; // Pass resolved data into component.
}

export default TeamCycleResolver;

export function getFontContrast(hexcolor) {
  // if # at the beginning of hexcolor, remove #
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Gets YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast and determines if font should be black or white
  return yiq >= 128 ? "#161616" : "#F6F6F6";
}

export function sumNumbers(...numbers) {
  return numbers.reduce((total, numbers) => total + numbers , 0);
}

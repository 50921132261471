import { Types } from "../constants/types";

const typeLabels = [Types.STORY, Types.BUG, Types.TASK];

export default function getIssueType(issueLabels) {
  return issueLabels
    .filter(({ name }) => typeLabels.includes(name))
    .map(({ name }) => name)
    .join(", ");
}

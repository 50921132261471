import React, { useMemo } from "react";

import useCycleMidsprintIssuesQuery from "../../graphql/hooks/useCycleMidsprintIssuesQuery";
import useCycleIssuesQuery from "../../graphql/hooks/useCycleIssuesQuery";
import { Types } from "../../constants/types";
import sumObjectValuesByKey from "../../utils/sumObjectValuesByKey";

import Container from "../Container/Container";
import Table from "../Table/Table";

function CycleScopeChange({ cycleId, filters, ...props }) {
  const { data: cycleData } = useCycleIssuesQuery(cycleId, filters);
  const { data: midsprintIssuesData } = useCycleMidsprintIssuesQuery(
    cycleId,
    filters
  );

  const columns = [
    {
      id: "Platform",
      children: "Platform",
    },
    {
      id: "stories",
      children: "# Stories",
      textAlign: "right",
    },
    {
      id: "points",
      children: "Points",
      textAlign: "right",
    },
    {
      id: "bugs",
      children: "# Bugs",
      textAlign: "right",
    },
    {
      id: "change",
      children: "% Change",
      textAlign: "right",
    },
  ];

  const totalPoints = useMemo(() => {
    let totalPoints = 0;

    if (cycleData) {
      const cycleStart = new Date(cycleData.cycle.startsAt);
      const beforeSprintIssues = cycleData.cycle.issues.filter((issue) => {
        return new Date(issue.createdAt).valueOf() < cycleStart.valueOf();
      });
      totalPoints = sumObjectValuesByKey(beforeSprintIssues, "estimate");
    }

    return totalPoints;
  }, [cycleData]);

  const NO_PLATFORM = "NO_PLATFORM";

  const scopeByPlatform = useMemo(() => {
    const defaultPlatformRow = {
      stories: 0,
      points: 0,
      bugs: 0,
    };
    const scopeByPlatform = {};

    if (midsprintIssuesData) {
      // Map to scope by platform
      midsprintIssuesData.midsprintIssues
        .filter((i) => i.state.stateType !== "canceled") // Filter out all Canceled state tickets
        .forEach((issue) => {
          const labels = issue.labels?.map((label) => label.name) || [];

          const addToScope = (platform, issue) => {
            if (!scopeByPlatform[platform]) {
              scopeByPlatform[platform] = { ...defaultPlatformRow };
            }

            if (labels.includes(Types.STORY)) {
              scopeByPlatform[platform].stories += 1; // 1 for the story
            }

            if (labels.includes(Types.BUG)) {
              scopeByPlatform[platform].bugs += 1; // 1 for the bug
            }

            if (issue.estimate) {
              scopeByPlatform[platform].points += issue.estimate; // Sum estimates
            }
          };

          if (issue.platforms.length) {
            issue.platforms.forEach((platform) => {
              addToScope(platform.name, issue);
            });
          } else {
            // No platforms.
            const noPlatformLabel = NO_PLATFORM;
            addToScope(noPlatformLabel, issue);
          }
        });
    }

    return scopeByPlatform;
  }, [midsprintIssuesData]);

  const rows = useMemo(() => {
    return Object.keys(scopeByPlatform).map((platform) => {
      const { points } = scopeByPlatform[platform];
      const pointChangeText = points ? `+${points}` : 0;

      const percentageChangeText =
        points && totalPoints
          ? `${Math.ceil((points / totalPoints) * 100)}%`
          : 0;

      return [
        platform !== NO_PLATFORM ? platform : null,
        scopeByPlatform[platform].stories,
        pointChangeText,
        scopeByPlatform[platform].bugs,
        percentageChangeText,
      ];
    });
  }, [scopeByPlatform, totalPoints]);

  const totalChange = useMemo(() => {
    const scopeChangePoints = sumObjectValuesByKey(scopeByPlatform, "points");
    if (!scopeChangePoints && !totalPoints) return 0;
    return Math.ceil((scopeChangePoints / totalPoints) * 100);
  }, [scopeByPlatform, totalPoints]);

  return (
    <Container title="Scope Change" titleCount={`+${totalChange}%`}>
      <Table columns={columns} rows={rows} />
    </Container>
  );
}

export default CycleScopeChange;

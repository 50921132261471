import { useMemo } from "react";

import useCycleIssuesQuery from "../../graphql/hooks/useCycleIssuesQuery";
import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";
import { useWorkflowTable } from "../../graphql/hooks/useWorkflowStatesQuery";
import { countTotals } from "../../utils/countTotals";

import Container from "../Container/Container";
import Table from "../Table/Table";

import styles from "./TicketBreakdown.module.scss";

const columns = [
  { id: "Platform", children: "Platform" },
  { id: "Stories", children: "Stories" },
  { id: "Tasks", children: "Tasks" },
  { id: "Bugs", children: "Bugs" },
];

const totalByLabelState = (issues, workflowStatesTemplate) => {
  // Generate counts for all labels
  const labelsById = {};

  issues.forEach((issue) => {
    issue.labels.forEach((label) => {
      countTotals(labelsById, label, "label", issue, workflowStatesTemplate);
    });
  });

  return labelsById;
};

const TicketBreakdown = ({ teamId, cycleId, filters }) => {
  const { data: cycleData } = useTeamCycleQuery(teamId);
  const { data, loading } = useCycleIssuesQuery(cycleId, filters);
  const { workflowStatesTemplate } = useWorkflowTable(cycleId);
  const issues = data?.cycle?.issues;

  const labelsById = useMemo(
    () => totalByLabelState(issues || [], workflowStatesTemplate),
    [issues, workflowStatesTemplate]
  );

  const allPlatforms = cycleData?.team?.platforms || [];

  // If there are any selected Platform label filters, use those.
  // Otherwise fallback to all platform labels.
  const filteredPlatforms =
    filters.platforms.length > 0
      ? allPlatforms.filter((platform) =>
          filters.platforms.includes(platform.name)
        )
      : allPlatforms;

  // Pick out the platform labels from all label totals.
  const platformsById = filteredPlatforms.reduce((platforms, platform) => {
    if (labelsById[platform.id]) {
      platforms[platform.id] = labelsById[platform.id];
    }
    return platforms;
  }, {});

  const { rows, totalTickets } = useMemo(() => {
    let totalStory = 0;
    let totalTask = 0;
    let totalBug = 0;
    let total = 0;

    const formatPercent = (value, total) =>
      `${Math.round((value / total) * 100)}%`;

    const platformRows = Object.values(platformsById).map((platform) => {
      const specialTotal =
        platform.subtotals.Story.total +
        platform.subtotals.Task.total +
        platform.subtotals.Bug.total;

      totalStory += platform.subtotals.Story.total;
      totalTask += platform.subtotals.Task.total;
      totalBug += platform.subtotals.Bug.total;
      total += specialTotal;

      return [
        platform.label.name,
        `${platform.subtotals.Story.total} (${formatPercent(
          platform.subtotals.Story.total,
          specialTotal
        )})`,
        `${platform.subtotals.Task.total} (${formatPercent(
          platform.subtotals.Task.total,
          specialTotal
        )})`,
        `${platform.subtotals.Bug.total} (${formatPercent(
          platform.subtotals.Bug.total,
          specialTotal
        )})`,
      ];
    });

    const totalRow = [
      "Total",
      `${totalStory} (${formatPercent(totalStory, total)})`,
      `${totalTask} (${formatPercent(totalTask, total)})`,
      `${totalBug} (${formatPercent(totalBug, total)})`,
    ];

    platformRows.push(totalRow);
    return { rows: platformRows, totalTickets: total };
  }, [platformsById]);

  return (
    <Container
      title="Ticket Breakdown"
      titleCount={totalTickets}
      loading={loading}
    >
      <Table rows={rows} columns={columns} className={styles.Table}></Table>
    </Container>
  );
};

export default TicketBreakdown;

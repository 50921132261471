import React from "react";
import classnames from "classnames";

import styles from "../Table/Table.module.scss";

const ALIGNMENT_CLASS_MAP = {
  left: "CellAlignLeft",
  right: "CellAlignRight",
  center: "CellAlignCenter",
};

function TableCell({
  children,
  className,
  textAlign = "left",
  header,
  ...props
}) {
  const TableCellTag = header ? "th" : "td";

  return (
    <TableCellTag
      className={classnames(
        className,
        styles.Cell,
        textAlign && styles[ALIGNMENT_CLASS_MAP[textAlign]]
      )}
      {...props}
    >
      {children ?? <span className={styles.CellEmpty}>—</span>}
    </TableCellTag>
  );
}

function Table({
  columns = [],
  rows = [],
  className,
  alternatingRows,
  hasTotalRow,
  hiddenHeader,
  ...props
}) {
  return (
    <table
      {...props}
      className={classnames(
        styles.Table,
        alternatingRows && styles.TableAlternatingRows,
        hasTotalRow && styles.TableWithTotal
      )}
    >
      {!!columns.length && (
        <thead
          className={classnames(
            styles.Header,
            hiddenHeader && styles.HeaderHidden
          )}
        >
          <tr className={styles.Row}>
            {columns.map(({ id, children: cellChildren, ...cellProps }) => {
              return (
                <TableCell key={id} header {...cellProps}>
                  {hiddenHeader ? (
                    <span className="sr-only">{cellChildren}</span>
                  ) : (
                    cellChildren
                  )}
                </TableCell>
              );
            })}
          </tr>
        </thead>
      )}
      <tbody className={styles.TableBody}>
        {rows &&
          rows.map((cells, rowIndex) => {
            return (
              <tr key={rowIndex} className={styles.Row}>
                {cells.map((cell, cellIndex) => {
                  return (
                    <TableCell
                      key={`${rowIndex}-${cellIndex}`}
                      textAlign={columns?.[cellIndex]?.textAlign}
                    >
                      {cell}
                    </TableCell>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

export default Table;

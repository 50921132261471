import { useCallback, useState } from "react";
import { generatePath, useHistory } from "react-router";

import { Routes } from "../../constants/routes";
import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";

import Fieldset from "../Fieldset/Fieldset";

import styles from "./ReportFilters.module.scss";

const routes = {
  active: Routes.ACTIVE_REPORT,
  "sprint-end": Routes.SPRINT_END_REPORT,
};

function ReportFilters({ teams, cycles, team, cycle, report }) {
  const history = useHistory();
  const teamId = team?.id;
  const cycleId = cycle?.id;
  const [selectedReport] = useState(report);
  const { data: cycleData } = useTeamCycleQuery(teamId);

  const baseRoute = routes[report];

  const handleReportChange = useCallback(
    (e) => {
      const route = routes[e.target.value];
      const params = {};

      if (team?.key) {
        params.teamKey = team.key;
      }

      if (cycle?.number) {
        params.cycleNumber = cycle.number;
      }

      history.push(generatePath(route, params));
    },
    [team, cycle, history]
  );

  const handleTeamChange = useCallback(
    (e) => {
      const t = teams?.find((t) => t.id === e.target.value);

      const params = {};

      if (t?.key) {
        params.teamKey = t.key;
      }

      history.push(generatePath(baseRoute, params));
    },
    [baseRoute, teams, history]
  );

  const handleCycleChange = useCallback(
    (e) => {
      const c = cycles?.find((c) => c.id === e.target.value);
      const params = {};

      if (team?.key) {
        params.teamKey = team.key;
      }

      if (c?.number) {
        params.cycleNumber = c.number;
      }

      history.push(generatePath(baseRoute, params));
    },
    [baseRoute, cycles, team, history]
  );

  return (
    <>
      <Fieldset
        className={styles.Select}
        type="select"
        value={selectedReport}
        onChange={handleReportChange}
      >
        <option disabled value="">
          Select Report…
        </option>
        <option value="active">Active Report</option>
        <option value="sprint-end">Sprint End Report</option>
      </Fieldset>

      <Fieldset
        className={styles.Select}
        type="select"
        value={teamId || ""}
        onChange={handleTeamChange}
      >
        <option disabled value="">
          Select Project…
        </option>
        {teams.map((team) => (
          <option value={team.id} key={team.id}>
            {team.name}
            {team.key && ` (${team.key})`}
          </option>
        ))}
      </Fieldset>
      <Fieldset
        className={styles.Select}
        type="select"
        value={cycleId || ""}
        onChange={handleCycleChange}
        disabled={!cycleData}
      >
        <option disabled value="">
          Select Cycle…
        </option>
        {cycles &&
          cycles.map((cycle) => (
            <option value={cycle.id} key={cycle.id}>
              Cycle {cycle.number}
              {team?.activeCycle?.id === cycle.id && " (Current)"}
            </option>
          ))}
      </Fieldset>
    </>
  );
}

export default ReportFilters;

import { useMemo } from "react";
import { PieChart } from "react-minimal-pie-chart";

import useCycleIssuesQuery from "../../graphql/hooks/useCycleIssuesQuery";

import { ReactComponent as Ellipsis } from "../../assets/images/ellipsis.svg";

import Container from "../Container/Container";
import Tooltip from "../Tooltip/Tooltip";

import styles from "./SprintFocus.module.scss";

const Legend = ({ data, total }) => {
  return (
    <ul className={styles.Legend}>
      {data.map((datum) => (
        <li>
          <span
            className={styles.LabelColor}
            style={{ backgroundColor: datum.color }}
          ></span>
          {datum.title} - {datum.value}pts (
          {Math.floor((datum.value / total) * 100)}%)
        </li>
      ))}
    </ul>
  );
};

const SprintFocus = ({ cycleId, filters }) => {
  const { data, loading } = useCycleIssuesQuery(cycleId, filters);

  // sum all tickets by spceial type.
  const projectChartData = useMemo(() => {
    const issues = data?.cycle?.issues || [];
    const projectTotals = [];

    issues.forEach((issue) => {
      if (!issue.project) return;

      const index = projectTotals.findIndex(
        (total) => total.title === issue.project.name
      );
      if (index < 0) {
        projectTotals.push({
          title: issue.project.name,
          value: issue.estimate || 0,
          color: issue.project.color,
        });
      } else {
        projectTotals[index].value += issue.estimate || 0;
        projectTotals[index].color = issue.project.color;
      }
    });

    return projectTotals.filter((project) => project.value > 0);
  }, [data]);

  const total = projectChartData.reduce((total, item) => {
    return total + item.value;
  }, 0);

  const sortedChartData = projectChartData.sort((a, b) => b.value - a.value);
  const topChartData = sortedChartData.slice(0, 3);
  const restChartData = sortedChartData.slice(3);

  return (
    <Container
      title="Sprint Focus"
      titleCount={`${total}pts`}
      empty={!total}
      loading={loading}
    >
      <div className={styles.PieChartContainer}>
        <div className={styles.Chart}>
          <PieChart data={sortedChartData} />
        </div>
        <div>
          <Legend data={topChartData} total={total} />
        </div>
      </div>

      {restChartData.length > 0 && (
        <div className={styles.More}>
          <Tooltip content={<Legend data={restChartData} total={total} />}>
            <div className={styles.Ellipsis}>
              <Ellipsis />
            </div>
          </Tooltip>
        </div>
      )}
    </Container>
  );
};

export default SprintFocus;

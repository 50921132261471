import { gql, useMutation } from "@apollo/client";

const TRIGGER_SYNC = gql`
  mutation {
    refreshLinearData {
      lastRefreshAt
    }
  }
`;

export default function useSyncMutation() {
  return useMutation(TRIGGER_SYNC);
}

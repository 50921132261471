import { gql, useQuery } from "@apollo/client";

const CYCLE_ISSUES_QUERY = gql`
  query cycleIssues(
    $cycleId: String!
    $platforms: [String]
    $types: [String]
    $labels: [String]
    $states: [String]
    $assignees: [String]
  ) {
    cycle(id: $cycleId) {
      id
      startsAt
      endsAt
      team {
        key
        workflowstates {
          id
          name
          color
          stateType
        }
      }
      issues(
        platforms: $platforms
        types: $types
        labels: $labels
        states: $states
        assignees: $assignees
      ) {
        id
        title
        number
        estimate
        createdAt
        project {
          name
          color
        }
        blocked
        labels {
          id
          name
          color
        }
        assignee {
          id
          name
          email
          avatarUrl
        }
        state {
          id
          name
          stateType
          color
        }
        platforms {
          name
        }
      }
    }
  }
`;

export default function useCycleIssuesQuery(cycleId, filters) {
  return useQuery(CYCLE_ISSUES_QUERY, {
    variables: {
      cycleId,
      ...filters,
    },
    skip: !cycleId,
  });
}

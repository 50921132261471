import React from "react";
import classnames from "classnames";

import styles from "./Count.module.scss";

function Count({
  className,
  children,
  reversed,
  ...props
}) {
  return (
    <span
      className={classnames(
        className,
        styles.Count,
        reversed && styles.CountReversed
      )}
      {...props}
    >
      <span>{children}</span>
    </span>
  );
};

export default Count;

import React from "react";
import classnames from "classnames";

import Count from "../Count/Count";
import Spinner from "../Spinner/Spinner";
import Message from "../Message/Message";

import styles from "../Container/Container.module.scss";

const STATE_IDLE = "idle";
const STATE_LOADING = "loading";
const STATE_ERROR = "error";
const STATE_EMPTY = "empty";

function Container({
  title,
  titleCount = null,
  loading,
  headerChildren,
  empty,
  emptyMessage = "No data to display",
  error,
  children,
  className,
  ...props
}) {
  let state = STATE_IDLE;
  if (error) state = STATE_ERROR;
  else if (loading) state = STATE_LOADING;
  else if (empty) state = STATE_EMPTY;

  const printStyle = empty ? "no-print" : "";

  return (
    <div
      {...props}
      className={classnames(styles.Container, className, printStyle)}
    >
      {title && (
        <header className={styles.Header}>
          <h2 className={styles.Title}>
            {title}
            {state === STATE_IDLE && (!!titleCount || titleCount === 0) && (
              <Count reversed>{titleCount}</Count>
            )}
          </h2>
          {headerChildren}
        </header>
      )}
      <div
        className={classnames(
          styles.Children,
          state !== STATE_IDLE && styles.ChildrenNotIdle
        )}
      >
        {state === STATE_IDLE && children}
        {state === STATE_ERROR && (
          <Message error>{error.message || "Something went wrong!"}</Message>
        )}
        {state === STATE_LOADING && <Spinner className={styles.Spinner} />}
        {state === STATE_EMPTY && <Message>{emptyMessage}</Message>}
      </div>
    </div>
  );
}
export default Container;

import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from "./Tooltip.module.scss";

const TooltipPopper = ({ containerRef, content, show }) => {
  const mount = document.getElementById("tooltip-root");
  const popperRef = useRef();
  const [position, setPosition] = useState({});

  useEffect(() => {
    // x,y position of where to draw the tooltip.
    // Get coords and draw.
    const el = containerRef.current;

    if (!el) return;

    const containerRect = el.getBoundingClientRect();
    const coords = {
      top: containerRect.top,
      left: containerRect.left + containerRect.width / 2,
    };
    const popperEl = popperRef.current;

    if (!popperEl) return;

    const buffer = 8;
    const rect = popperEl.getBoundingClientRect();

    const newPosition = {
      top: coords.top - rect.height - buffer, // 8 as a buffer for the triangle
      left: coords.left - rect.width / 2,
    };

    setPosition(newPosition);
  }, [containerRef, show]);

  if (!show) return null;

  return createPortal(
    <div ref={popperRef} className={styles.TooltipPopper} style={position}>
      {content}
      <div className={styles.ArrowDown}></div>
    </div>,
    mount
  );
};

const Tooltip = ({ content, children }) => {
  const tooltipRef = useRef();
  const [visible, setVisible] = useState(false);

  const onMouseEnter = () => {
    setVisible(true);
  };

  const onMouseLeave = () => {
    setVisible(false);
  };

  const childrenProps = {
    ...children.props,
    onMouseEnter,
    onMouseLeave,
  };

  // Clone the child element to transclude with new event handler and class
  const childClone = React.cloneElement(children, childrenProps);

  return (
    <div ref={tooltipRef} className={styles.TooltipWrapper}>
      {/* Transclude child components */}
      {childClone}

      {/* Draw tooltip content throguh a portal */}
      <TooltipPopper
        containerRef={tooltipRef}
        content={content}
        show={visible}
      ></TooltipPopper>
    </div>
  );
};

export default Tooltip;

import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";

import { sortObjectValueAlpha } from "../../utils/sortObjectValueAlpha";
import useSetMainHeading from "../../hooks/useSetMainHeading";
import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";
import useTeamQuery from "../../graphql/hooks/useTeamQuery";

import Message from "../Message/Message";
import SprintEndReportSidebar from "../SprintEndReportSidebar/SprintEndReportSidebar";
import MainContent from "../MainContent/MainContent";
import Spinner from "../Spinner/Spinner";
import FinalTicketStatus from "../FinalTicketStatus/FinalTicketStatus";
import SprintFocus from "../SprintFocus/SprintFocus";
import TicketOverview from "../TicketOverview/TicketOverview";
import Velocity from "../Velocity/Velocity";
import TicketBreakdown from "../TicketBreakdown/TicketBreakdown";
import SprintEndBlockedTable from "../SprintEndBlockedTable/SprintEndBlockedTable";
import MidsprintTicketsTable from "../MidsprintTicketsTable/MidsprintTicketsTable";
import ScopeChangeOverview from "../ScopeChangeOverview/ScopeChangeOverview";

import styles from "./SprintEndReport.module.scss";

const pageTitle = "Sprint End Report";

export function SprintEndReport({ team, cycle }) {
  useSetMainHeading(pageTitle);

  const [filters, setFilters] = useState({
    platforms: [],
    assignees: [],
  });

  // Fetch team and cycle from params, fallback to team's
  // active cycle if no param given.
  const { loading, error, data: teamsData } = useTeamQuery();
  const { data: cycleData } = useTeamCycleQuery(team?.id);
  const cycles = cycleData?.team?.cycles;

  const teamId = team?.id;
  const cycleId = cycle?.id;

  const sortedTeams = useMemo(() => {
    if (!teamsData) return;

    return [...teamsData.teams].sort(sortObjectValueAlpha("name"));
  }, [teamsData]);

  if (loading || error || !teamsData) {
    return (
      <div className={styles.SprintEndReport}>
        {loading && <Spinner className={styles.Spinner} />}
        <MainContent>
          {!loading && (
            <Message error>{error?.message || "Something went wrong"}</Message>
          )}
        </MainContent>
      </div>
    );
  }

  return (
    <div className={styles.ClientReport}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div className="no-print">
        <SprintEndReportSidebar
          teams={sortedTeams}
          cycles={cycles}
          filters={filters}
          team={team}
          cycle={cycle}
          onFiltersChange={(newFilters) => {
            setFilters((f) => {
              return {
                ...f,
                ...newFilters,
              };
            });
          }}
        />
      </div>

      <MainContent>
        {!teamId && <Message>Choose a project…</Message>}

        {teamId && !cycleId && <Message>Choose a cycle…</Message>}

        {teamId && cycleId && (
          <>
            <FinalTicketStatus cycleId={cycleId} filters={filters} />
            <div className={styles.Row}>
              <SprintFocus cycleId={cycleId} filters={filters} />
              <TicketOverview cycleId={cycleId} filters={filters} />
            </div>
            <div className={styles.Row}>
              <Velocity teamId={teamId} cycleId={cycleId} filters={filters} />

              <TicketBreakdown
                teamId={teamId}
                cycleId={cycleId}
                filters={filters}
              />
            </div>

            <h2 className={styles.SectionHeading}>Ticket Status</h2>

            <SprintEndBlockedTable cycleId={cycleId} filters={filters} />

            <h2 className={styles.SectionHeading}>Scope Change</h2>

            <ScopeChangeOverview
              teamId={teamId}
              cycleId={cycleId}
              filters={filters}
            />

            <MidsprintTicketsTable
              cycleId={cycleId}
              filters={filters}
              fields={[
                "Platform",
                "ID",
                "Summary",
                "Type",
                "Epic",
                "Status",
                "Points",
              ]}
            />
          </>
        )}
      </MainContent>
    </div>
  );
}

export default SprintEndReport;

import React from "react";
import { Redirect, Route } from "react-router-dom";

import AuthService from "../../services/auth.service";

const PrivateRoute = ({ component: Component, render, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      AuthService.isAuthenticated() ? (
        render ? (
          render(props)
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default PrivateRoute;

import React, { useMemo } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import useCycleAssigneeTimeOff from "../../graphql/hooks/useCycleAssigneeTimeOff";
import dateLoop from "../../utils/dateLoop";
import generateWeeks from "../../utils/generateWeeks";
import Container from "../Container/Container";
import Table from "../Table/Table";
import Scrollable from "../Scrollable/Scrollable";

import styles from "./PTOTable.module.scss";

dayjs.extend(advancedFormat);

const COLUMNS = [
  { width: "30%", children: "Day" },
  { width: "70%", children: "Team members off" },
];

const addTimeOffDay = (days, day, name) => {
  const key = day.format("YYYY-MM-DD");
  if (!days[key]) days[key] = [];

  if (!days[key].includes(name)) {
    days[key].push(name);
  }
  return days;
};

const generateTimeOffWeekdays = (cycle, filters) => {
  const cycleStartAt = dayjs.tz(cycle.startsAt).startOf("day");
  const cycleEndAt = dayjs.tz(cycle.endsAt).endOf("day");
  const dayMap = generateWeeks(cycle.startsAt, cycle.endsAt, true);

  const assigneeTimeOff =
    cycle?.assigneeTimeOff?.map((assigneeTimeOff) => {
      const userName = assigneeTimeOff.user.name.split(" ")[0];
      return {
        startDate: assigneeTimeOff.startDate,
        endDate: assigneeTimeOff.endDate,
        label: userName,
      };
    }) || [];
  const holidayTimeOff =
    cycle?.holidayTimeOff?.map((holidayTimeOff) => {
      const regionName = `${holidayTimeOff.region.name} Holiday`;
      return {
        startDate: holidayTimeOff.startDate,
        endDate: holidayTimeOff.endDate,
        label: regionName,
      };
    }) || [];

  // Join all dates to a single list
  const timeOffEvents = assigneeTimeOff.concat(holidayTimeOff);

  // Loop through all time off events.
  for (const timeOff of timeOffEvents) {
    let startDate = dayjs.tz(timeOff.startDate).startOf("day"); // Start date could be before
    let endDate = timeOff.endDate
      ? dayjs.tz(timeOff.endDate).endOf("day")
      : null;

    // Check if start date is before cycle start date.
    if (cycleStartAt > startDate) {
      startDate = cycleStartAt;
    }

    // Check if end is beyond cycle end date.
    if (cycleEndAt < endDate) {
      endDate = cycleEndAt;
    }

    if (endDate) {
      // Loop through the start -> end dates and add each day to the time off.
      dateLoop(
        startDate,
        endDate,
        (currentDay) => {
          addTimeOffDay(dayMap, currentDay, timeOff.label);
        },
        {
          skipWeekendDays: true,
        }
      );
    } else {
      // They are only off for one day, add this single day off.
      addTimeOffDay(dayMap, startDate, timeOff.label);
    }
  }

  return dayMap;
};

function PTOTable({ cycleId, filters, ...props }) {
  const {
    data: timeOffData,
    loading,
    error,
  } = useCycleAssigneeTimeOff(cycleId);

  const timeOffMap = useMemo(() => {
    if (!timeOffData) return {};
    return generateTimeOffWeekdays(timeOffData.cycle, filters);
  }, [timeOffData, filters]);

  // Build the rows for the table.
  const timeOffWeeks = useMemo(() => {
    return Object.keys(timeOffMap).map((k) => ({
      date: dayjs.tz(k),
      assignees: timeOffMap[k],
    }));
  }, [timeOffMap]);

  // Sum all individual's time off as a day off.
  const totalDaysOff = useMemo(() => {
    return Object.values(timeOffMap).reduce((total, day) => {
      return (total += day.length);
    }, 0);
  }, [timeOffMap]);

  // Build the format of the rows.
  const rows = useMemo(() => {
    return timeOffWeeks.map((row) => {
      const rowDate = row.date;
      return [
        rowDate.format("dddd MMMM Do"), // Monday April 13th
        row.assignees?.length ? row.assignees.join(", ") : null, // Comma separated names
      ];
    });
  }, [timeOffWeeks]);

  return (
    <Container
      loading={loading}
      error={error}
      title={<abbr title="Paid Time Off">PTO</abbr>}
      empty={!totalDaysOff}
      emptyMessage="No PTO in this cycle"
      titleCount={`${totalDaysOff} Day${totalDaysOff !== 1 ? "s" : ""} Off`}
      {...props}
    >
      {timeOffWeeks && (
        <Scrollable className={styles.PTOScrollable}>
          <Table
            className={styles.PTOTable}
            rows={rows}
            hiddenHeader
            columns={COLUMNS}
          />
        </Scrollable>
      )}
    </Container>
  );
}

export default PTOTable;

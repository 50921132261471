import { useEffect, useState } from "react";

/**
 * Image loading hook to determine if a src
 * image is loading or has failed to load.
 *
 * @export
 * @param {*} { src }
 * @return {*}
 */
export default function useImageLoaded({ src }) {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!src) {
      return;
    }

    setLoaded(false);

    // Active flag to make sure component in use
    // is still mounted.
    let active = true;
    const image = new Image();
    image.src = src;
    image.onload = () => {
      if (!active) {
        return;
      }
      setLoaded("loaded");
    };

    image.onerror = () => {
      if (!active) {
        return;
      }
      setLoaded("error");
    };

    return () => {
      active = false;
    };
  }, [src]);

  return loaded;
}

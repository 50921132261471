import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";

import useTeamQuery from "../../graphql/hooks/useTeamQuery";
import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";

import { sortObjectValueAlpha } from "../../utils/sortObjectValueAlpha";
import useSetMainHeading from "../../hooks/useSetMainHeading";

import MainContent from "../MainContent/MainContent";
import Spinner from "../Spinner/Spinner";
import Message from "../Message/Message";
import ProjectProgress from "../ProjectProgress";
import CycleScopeChange from "../CycleScopeChange/CycleScopeChange";
import PointsByStatusTable from "../PointsByStatusTable/PointsByStatusTable";
import BlockedTable from "../BlockedTable/BlockedTable";
import MidsprintTicketsTable from "../MidsprintTicketsTable/MidsprintTicketsTable";
import ActiveReportSidebar from "../ActiveReportSidebar/ActiveReportSidebar";
import TicketCountByStatusTable from "../TicketCountByStatusTable/TicketCountByStatusTable";
import PTOTable from "../PTOTable/PTOTable";

import styles from "./ActiveReport.module.scss";

const pageTitle = "Active Project Report";

function ActiveReport({ team, cycle }) {
  useSetMainHeading(pageTitle);

  const [filters, setFilters] = useState({
    platforms: [],
    assignees: [],
  });

  // Fetch team and cycle from params, fallback to team's
  // active cycle if no param given.
  const { loading, error, data: teamsData } = useTeamQuery();
  const { data: cycleData } = useTeamCycleQuery(team?.id);
  const cycles = cycleData?.team?.cycles;
  const teamId = team?.id;
  const cycleId = cycle?.id;

  const invalidTeam = useMemo(() => {
    if (!teamId) return false;

    const selectedTeam = teamsData?.teams?.find(({ id }) => id === teamId);

    return selectedTeam?.cycles?.length === 0;
  }, [teamsData, teamId]);

  const sortedTeams = useMemo(() => {
    if (!teamsData) return;

    return [...teamsData.teams].sort(sortObjectValueAlpha("name"));
  }, [teamsData]);

  if (loading || error || !teamsData) {
    return (
      <div className={styles.ActiveReport}>
        {loading && <Spinner className={styles.Spinner} />}
        <MainContent>
          {!loading && (
            <Message error>{error?.message || "Something went wrong"}</Message>
          )}
        </MainContent>
      </div>
    );
  }

  return (
    <div className={styles.ActiveReport}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div className="no-print">
        <ActiveReportSidebar
          teams={sortedTeams}
          cycles={cycles}
          filters={filters}
          team={team}
          cycle={cycle}
          onFiltersChange={(newFilters) => {
            setFilters((f) => {
              return {
                ...f,
                ...newFilters,
              };
            });
          }}
        />
      </div>

      <MainContent>
        {!teamId && <Message>Choose a project…</Message>}

        {invalidTeam && <Message error>This team has no valid cycles.</Message>}

        {!invalidTeam && teamId && !cycleId && (
          <Message>Choose a cycle…</Message>
        )}

        {teamId && cycleId && (
          <>
            <ProjectProgress cycleId={cycleId} filters={filters} />

            <div className={styles.Row}>
              <CycleScopeChange cycleId={cycleId} filters={filters} />
              <PTOTable cycleId={cycleId} filters={filters} />
            </div>

            <BlockedTable cycleId={cycleId} filters={filters} />

            <TicketCountByStatusTable
              teamId={teamId}
              cycleId={cycleId}
              filters={filters}
            />

            <PointsByStatusTable
              teamId={teamId}
              cycleId={cycleId}
              filters={filters}
            />

            <MidsprintTicketsTable cycleId={cycleId} filters={filters} />
          </>
        )}
      </MainContent>
    </div>
  );
}

export default ActiveReport;

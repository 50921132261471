import { useMemo } from "react";
import { PieChart } from "react-minimal-pie-chart";

import { Types } from "../../constants/types";
import useCycleIssuesQuery from "../../graphql/hooks/useCycleIssuesQuery";

import Container from "../Container/Container";

import styles from "./TicketOverview.module.scss";

const TicketOverview = ({ cycleId, filters }) => {
  const { data, loading } = useCycleIssuesQuery(cycleId, filters);

  // sum all tickets by special type.
  const ticketChartData = useMemo(() => {
    const issues = data?.cycle?.issues || [];
    // Default all special label totals to zero.
    const ticketTotals = [
      {
        title: Types.STORY,
        value: 0,
      },
      {
        title: Types.BUG,
        value: 0,
      },
      {
        title: Types.TASK,
        value: 0,
      },
    ];

    issues.forEach((issue) => {
      issue.labels
        .filter(({ name }) =>
          [Types.STORY, Types.BUG, Types.TASK].includes(name)
        )
        .forEach((label) => {
          const index = ticketTotals.findIndex(
            (total) => total.title === label.name
          );
          if (index < 0) {
            ticketTotals.push({
              title: label.name,
              value: 1,
              color: label.color,
            });
          } else {
            ticketTotals[index].value++;
            ticketTotals[index].color = label.color;
          }
        });
    });

    return ticketTotals;
  }, [data]);

  const total = ticketChartData.reduce((total, item) => {
    return total + item.value;
  }, 0);

  return (
    <Container title="Ticket Overview" titleCount={total} loading={loading}>
      <div className={styles.PieChartContainer}>
        <div className={styles.Chart}>
          <PieChart data={ticketChartData} />
        </div>
        <div>
          <ul className={styles.Legend}>
            {ticketChartData
              .sort((a, b) => b.value - a.value)
              .map((datum) => (
                <li key={datum.title}>
                  <span
                    className={styles.LabelColor}
                    style={{ backgroundColor: datum.color }}
                  ></span>
                  {datum.title} - {datum.value} (
                  {Math.floor((datum.value / total) * 100)}%)
                </li>
              ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default TicketOverview;

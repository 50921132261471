import { Types } from "../constants/types";

/**
 * Prepare all values for the the table by workflow state.
 *
 *  Field totals shape: {
 *    [fieldId]: {
 *      platform: String,
 *      total: Number.
 *      counts: {
 *        [stateId]: Number
 *      }
 *      subtotals: {
 *        [type]: {
 *          type: string,
 *          total: Number
 *          counts: {
 *            [stateId]: Number
 *          }
 *        }
 *      }
 *    }
 *  }
 */
const specialTypes = Object.values(Types);

// Default shape of zeroed out totals.
const defaultTotals = (workflowStatesTemplate) => {
  return {
    total: 0,
    counts: { ...workflowStatesTemplate },
    subtotals: specialTypes.reduce((subtotals, type) => {
      subtotals[type] = {
        type: type,
        counts: { ...workflowStatesTemplate },
        total: 0,
      };
      return subtotals;
    }, {}),
  };
};

// Generic total counter based on a grouping by
// record type.
export const countTotals = (
  mapRef,
  record,
  recordName,
  issue,
  workflowStatesTemplate
) => {
  const recordId = record?.id;

  if (!recordId) return mapRef;

  // Initialize record totals.
  if (!mapRef[recordId]) {
    mapRef[recordId] = {
      ...defaultTotals(workflowStatesTemplate),
      [recordName]: record,
    };
  }

  // count totals
  mapRef[recordId].counts[issue.state.id]++;
  mapRef[recordId].total++;

  // Count subtotals by special labels.
  specialTypes.forEach((type) => {
    const labels = issue.labels.map(({ name }) => name);
    if (labels.includes(type)) {
      mapRef[recordId].subtotals[type].counts[issue.state.id]++;
      mapRef[recordId].subtotals[type].total++;
    }
  });

  return mapRef;
};

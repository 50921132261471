export default function sumObjectValuesByKey(objects, key) {
  return Object.values(objects).reduce((total, object) => {
    // ensure value is a number.
    const value = parseFloat(object[key]);
    if (isNaN(value)) {
      return total;
    }

    return total + (value || 0);
  }, 0);
}

import React, { useMemo } from "react";

import {
  completeStates,
  reviewStates,
  todoStates,
} from "../../constants/statuses";
import useCycleEstimatesQuery from "../../graphql/hooks/useCycleEstimatesQuery";
import { useWorkflowStates } from "../../graphql/hooks/useWorkflowStatesQuery";
import { mapStateToProgress } from "../../utils/mapStateToProgress";
import sumObjectValuesByKey from "../../utils/sumObjectValuesByKey";

import Container from "../Container/Container";
import ProgressChart from "../ProgressChart";

import styles from "./ProjectProgress.module.scss";

function ProjectProgress({ cycleId, filters, ...props }) {
  const {
    data: cycleEstimates,
    loading,
    error,
  } = useCycleEstimatesQuery(cycleId, filters);
  const { workflowStatesById } = useWorkflowStates(cycleId);
  const estimatesMap = useMemo(() => {
    const newMap = {};
    if (cycleEstimates?.cycle?.cycleEstimates) {
      for (const { state, estimate } of cycleEstimates.cycle.cycleEstimates) {
        newMap[state] = estimate;
      }
    }
    return newMap;
  }, [cycleEstimates]);

  const todoSegments = useMemo(
    () => todoStates.map(mapStateToProgress(estimatesMap, workflowStatesById)),
    [estimatesMap, workflowStatesById]
  );
  const reviewSegments = useMemo(
    () =>
      reviewStates.map(mapStateToProgress(estimatesMap, workflowStatesById)),
    [estimatesMap, workflowStatesById]
  );
  const completeSegments = useMemo(
    () =>
      completeStates.map(mapStateToProgress(estimatesMap, workflowStatesById)),
    [estimatesMap, workflowStatesById]
  );

  const todoTotal = useMemo(
    () => sumObjectValuesByKey(todoSegments, "value") || 0,
    [todoSegments]
  );
  const reviewTotal = useMemo(
    () => sumObjectValuesByKey(reviewSegments, "value") || 0,
    [reviewSegments]
  );

  const completeTotal = useMemo(
    () => sumObjectValuesByKey(completeSegments, "value") || 0,
    [completeSegments]
  );
  const totalPoints = todoTotal + reviewTotal;

  return (
    <Container
      title="Progress"
      loading={loading}
      error={error}
      empty={!cycleEstimates?.cycle?.cycleEstimates?.length}
      emptyMessage="No progress has been made in this cycle"
      {...props}
    >
      <div className={styles.ChartContainer}>
        <ProgressChart
          className={styles.Chart}
          label={`${todoTotal} points in development`}
          segments={todoSegments}
        />

        <ProgressChart
          className={styles.Chart}
          label={`${reviewTotal} points in QA`}
          segments={reviewSegments}
        />

        <ProgressChart
          className={styles.Chart}
          label={`${completeTotal} points completed`}
          segments={completeSegments}
        />
      </div>
      <div className={styles.TotalPoints}>{totalPoints} points total</div>
    </Container>
  );
}

export default ProjectProgress;

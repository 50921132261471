export function sortObjectValueAlpha(key, direction = "asc") {
  return function (a, b) {
    let order = 1;

    if (direction === "desc") {
      order = -1;
    }

    return a[key].localeCompare(b[key]) * order;
  };
}

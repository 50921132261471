import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import classNames from "classnames";
import dayjs from "dayjs";

import { SYNC_DURATION_MINUTES } from "../../config";
import AuthService from "../../services/auth.service";
import { MainHeadingContext } from "../../contexts/MainHeadingContext";

import useSyncMutation from "../../graphql/hooks/useSyncMutation";
import useTeamQuery from "../../graphql/hooks/useTeamQuery";

import Button, { BUTTON_SIZE_SMALL } from "../Button/Button";

import companyLogo from "../../assets/images/fueled-logo.svg";
import { ReactComponent as IconRefresh } from "./refresh.svg";

import styles from "./Nav.module.scss";

function Nav() {
  const history = useHistory();

  const { data } = useTeamQuery();
  const [syncMutation] = useSyncMutation();

  const { title } = useContext(MainHeadingContext);
  const [userAuthed, setUserAuthed] = useState(null);

  const [refreshSpinning, setRefreshSpinning] = useState(false);
  const lastSync = useMemo(() => {
    // Normally all the lastSyncAt of all teams are equal, so we just take the
    // first
    const date = data?.teams[0]?.workspace.lastSyncAt;
    if (!date) return null;
    return dayjs(date).format('DD/MM/YYYY h:mm');
  }, [data]);

  const sync = () => {
    syncMutation();
    setRefreshSpinning(true);
    setTimeout(
      () => setRefreshSpinning(false),
      SYNC_DURATION_MINUTES * 60 * 1000
    );
  };

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      setUserAuthed(true);
    }
    return AuthService.watchUser((newUser) => {
      setUserAuthed(!!newUser);
    });
  }, []);

  const handleLogout = useCallback(() => {
    AuthService.logout();
    history.push("/");
  }, [history]);

  return (
    <nav className={styles.Nav}>
      <div className={styles.Container}>
        <img
          className={styles.CompanyLogo}
          src={companyLogo}
          alt="Fueled Logo"
        />
        <div className={styles.Text}>
          <span className={styles.Title}>Dynamic Reporting</span>
          <span className={styles.Subtitle}>{title}</span>
        </div>

        {userAuthed && (
          <>
            <button className={classNames(styles.Refresh)} onClick={sync}>
              <div>
                Refresh
                <span>{lastSync}</span>
              </div>
              <IconRefresh
                className={classNames(refreshSpinning && styles.Spinning)}
                role="presentation"
              />
            </button>
            <Button
              className={classNames("no-print", styles.LogOut)}
              onClick={handleLogout}
              reversed
              size={BUTTON_SIZE_SMALL}
            >
              Log Out
            </Button>
          </>
        )}
      </div>
    </nav>
  );
}

export default Nav;

import React, { useMemo } from "react";
import classnames from "classnames";

import { getFontContrast } from "../../utils";
import sumObjectValuesByKey from "../../utils/sumObjectValuesByKey";

import styles from "./ProgressChart.module.scss";

const ProgressChartSegment = ({ value, total, color, ariaLabel }) => {
  const percentWidth = useMemo(() => {
    // No value and no total, take up the full display.
    if (value === 0 && total === 0) {
      return 100;
    }
    return Math.abs((value / total) * 100);
  }, [value, total]);

  const textColor = useMemo(
    () => (color ? getFontContrast(color) : undefined),
    [color]
  );

  const backgroundStyle = {
    width: `${percentWidth}%`,
    backgroundColor: color,
    color: textColor,
  };

  return (
    <div className={styles.ProgressChartSegment} style={backgroundStyle}>
      <span className={styles.ProgressChartSegmentValue} aria-label={ariaLabel}>
        {value}
      </span>
    </div>
  );
};

/**
 * Progress chart displays a collection of segments
 * in a horizontal barchart style with segments sized
 * proportionally to the total sum.
 *
 * @param {*} segments A segment data point of the chart to display.
 * @param {string} segments.id Segment identifier as the key for rendering.
 * @param {string} segments.label Label of the segment to identify the value.
 * @param {number} segments.value The value of the segment.
 * @param {string} segments.color The hex color for the segment background.
 * @param {string} label Label for the chart.
 * @param {*} className Container class name.
 * @param {*} labelClassName Label class name.
 * @return {*}
 */
const ProgressChart = ({ id, segments, label, className, labelClassName }) => {
  // Total can be inferred from sum of segment values.
  const total = useMemo(() => sumObjectValuesByKey(segments, "value"), [
    segments,
  ]);

  return (
    <div className={className}>
      {label && (
        <label className={classnames(styles.Label, labelClassName)} id={id}>
          {label}
        </label>
      )}

      <div className={styles.ProgressChart} aria-labelledby={label ? id : null}>
        {segments.map((segment) => (
          <ProgressChartSegment
            key={segment.id}
            value={segment.value || 0}
            color={segment.color}
            ariaLabel={`${segment.value} ${segment.label}`}
            total={total}
            minPercent={100 / segments.length}
          />
        ))}
      </div>
    </div>
  );
};

export default ProgressChart;

import React from "react";
import classnames from "classnames";

import Spinner from "../Spinner/Spinner";

import styles from "../Button/Button.module.scss";

export const BUTTON_SIZE_REGULAR = "regular";
export const BUTTON_SIZE_SMALL = "small";

function Button({
  className,
  children,
  size = BUTTON_SIZE_REGULAR,
  loading,
  reversed,
  disabled,
  ...props
}) {
  return (
    <button
      className={classnames(
        className,
        styles.Button,
        styles[size],
        reversed && styles.reversed,
        loading && styles.loading
      )}
      disabled={disabled || loading}
      {...props}
    >
      <span className={styles.Children}>{children}</span>
      {loading && <Spinner className={styles.Spinner} />}
    </button>
  );
}
export default Button;

import { gql, useQuery } from "@apollo/client";

const CYCLE_ESTIMATES = gql`
  query cycleIssueEstimates(
    $cycle: String!
    $platforms: [String]
    $types: [String]
    $labels: [String]
    $states: [String]
    $assignees: [String]
  ) {
    cycle(id: $cycle) {
      id
      cycleEstimates(
        platforms: $platforms
        types: $types
        labels: $labels
        states: $states
        assignees: $assignees
      ) {
        state
        estimate
      }
    }
  }
`;

export default function useCycleEstimatesQuery(cycleId, filters) {
  return useQuery(CYCLE_ESTIMATES, {
    variables: {
      ...filters,
      cycle: cycleId,
    },
  });
}

import React from "react";
import classnames from "classnames";

import styles from "./Message.module.scss";

function Message({
  className,
  children,
  error,
  ...props
}) {
  return (
    <p
      className={classnames(
        className,
        styles.Message,
        error && styles.MessageError
      )}
      {...props}
    >
      {children}
    </p>
  );
};

export default Message;

export function filterByLabels(issues, labels) {
  if (!Array.isArray(labels)) {
    labels = [labels];
  }

  return issues.filter((issue) => {
    const issueLabels = issue.labels.map(({ name }) => name);
    return labels.every((i) => issueLabels.includes(i));
  });
}

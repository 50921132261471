import React from "react";
import classnames from "classnames";

import useImageLoaded from "../../hooks/useImageLoaded";

import styles from "./Avatar.module.scss";

const Avatar = ({
  src,
  alt,
  className,
  imgProps,
  variant,
  size,
  children: childrenProp,
  ...props
}) => {
  const loaded = useImageLoaded({ src });
  const hasImg = !!src;
  const hasImgNotFailing = hasImg && loaded !== "error";

  let children = null;

  if (hasImgNotFailing) {
    children = <img alt={alt} src={src} {...imgProps} />;
  } else if (childrenProp != null) {
    // Use the children as the prop.
    children = childrenProp;
  } else if (hasImg && alt) {
    // Show the first letter of alt if the image didn't load.
    children = alt[0];
  }

  return (
    <div
      className={classnames(
        styles.Avatar,
        styles[variant],
        styles[size],
        className
      )}
      title={alt}
      {...props}
    >
      {children}
    </div>
  );
};

export default Avatar;

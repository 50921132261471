import useCycleIssuesQuery from "./useCycleIssuesQuery";

export default function useCycleMidsprintIssuesQuery(cycleId, filters) {
  const { data, ...others } = useCycleIssuesQuery(cycleId, filters);

  if (data) {
    // Filter out all issues that are created midsprint
    const cycleStart = new Date(data.cycle.startsAt);
    const cycleEnd = new Date(data.cycle.endsAt);
    const midsprintIssues = data.cycle.issues.filter((issue) => {
      const issueCreatedAt = new Date(issue.createdAt);
      return issueCreatedAt > cycleStart && issueCreatedAt < cycleEnd;
    });

    return {
      data: {
        cycle: data.cycle,
        midsprintIssues,
      },
      ...others,
    };
  }

  return {
    data,
    ...others,
  };
}

import React from "react";

import styles from "./Checkbox.module.scss";

function Checkbox({ children, checked, value, onChange, ...props }) {
  return (
    <label className={styles.LabelContainer} {...props}>
      <input
        className={styles.Input}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.Checkbox}>
        <svg
          className={styles.CheckIcon}
          viewBox="0 0 14 11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.16669 4.58335L5.33335 8.75002L12.4167 1.66669" />
        </svg>
      </span>
      <span className={styles.LabelText}>{children}</span>
    </label>
  );
}
export default Checkbox;

import React, { useMemo } from "react";

import useCycleMidsprintIssuesQuery from "../../graphql/hooks/useCycleMidsprintIssuesQuery";
import { useWorkflowStates } from "../../graphql/hooks/useWorkflowStatesQuery";

import { buildLinearLink } from "../../utils/buildLinearLink";
import getIssueType from "../../utils/getIssueType";
import getIssuePlatforms from "../../utils/getIssuePlatforms";

import Assignee from "../Assignee/Assignee";
import Container from "../Container/Container";
import Table from "../Table/Table";

const COLUMNS = {
  Platform: {
    id: "Platform",
    children: "Platform",
    field: (cycle, issue) =>
      issue.platforms.map((p) => p.name).join(" ") || null,
  },
  ID: {
    id: "ID",
    children: "ID",
    field: (cycle, issue) => buildLinearLink(cycle.team.key, issue.number),
  },
  Type: {
    id: "Type",
    children: "Type",
    field: (cycle, issue) => getIssueType(issue.labels) || null, // Fall back to null if falsy.
  },
  Summary: {
    id: "Summary",
    children: "Summary",
    field: (cycle, issue) => issue.title,
  },
  Epic: {
    id: "Epic",
    children: "Epic",
    field: (cycle, issue) => issue.project?.name,
  },
  Status: {
    id: "Status",
    children: "Status",
    field: (cycle, issue, workflowStatesById) =>
      workflowStatesById[issue.state.id]?.Pill,
  },
  Points: {
    id: "Points",
    children: "Points",
    textAlign: "right",
    field: (cycle, issue) => issue.estimate || 0,
  },
  Assignee: {
    id: "Assignee",
    children: "Assignee",
    field: (cycle, issue) =>
      issue.assignee ? <Assignee assignee={issue.assignee} /> : null,
  },
};

// Default original order of keys.
const allFields = Object.keys(COLUMNS).map((col) => col);

function MidsprintTicketsTable({
  cycleId,
  filters,
  fields = allFields,
  ...props
}) {
  const { loading, data, error } = useCycleMidsprintIssuesQuery(
    cycleId,
    filters
  );
  const { workflowStatesById } = useWorkflowStates(cycleId);

  // Map columns to user specific field order.
  const columns = useMemo(() => {
    return fields.map((col) => COLUMNS[col]);
  }, [fields]);

  const rows = useMemo(() => {
    if (loading || !data) return null;

    return data.midsprintIssues
      .sort((issueA, issueB) =>
        getIssuePlatforms(issueA)
          .join(" ")
          .localeCompare(getIssuePlatforms(issueB).join(" "))
      )
      .map((issue) => {
        // Render each of the provided fields with cycle/issue data
        return fields.map((col) =>
          COLUMNS[col].field(data.cycle, issue, workflowStatesById)
        );
      });
  }, [fields, data, loading, workflowStatesById]);

  return (
    <Container
      title="Midsprint Tickets Added"
      titleCount={rows?.length}
      loading={loading}
      error={error}
      empty={!rows?.length}
      emptyMessage="No midsprint issues added to this cycle"
    >
      {rows && <Table columns={columns} rows={rows} />}
    </Container>
  );
}

export default MidsprintTicketsTable;

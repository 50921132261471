import { gql, useQuery } from "@apollo/client";

const ASSIGNEE_TIME_OFF_QUERY = gql`
  query assigneeTimeOff($cycle: String!) {
    cycle(id: $cycle) {
      id
      startsAt
      endsAt
      assigneeTimeOff {
        user {
          id
          name
          email
        }
        eventType
        startDate
        endDate
      }
      holidayTimeOff {
        name
        startDate
        endDate
        region {
          name
        }
      }
    }
  }
`;

export default function useCycleAssigneeTimeOff(cycleId) {
  return useQuery(ASSIGNEE_TIME_OFF_QUERY, {
    variables: {
      cycle: cycleId,
    },
  });
}

export function validateEmail(email) {
  let error = '';
  if(!email) {
    error = "Email address required";
  } else if(!/\S+@\S+\.\S+/.test(email)){
    error = "Email address is invalid";
  }
  return error;
}

export function validatePassword(password) {
  let error = '';
    if(!password) {
      error = "Password required";
    }
    else if (password.trim().length < 6) {
      error = "Password must be longer than 6 characters";
    }
    else if (password.toLowerCase() === "password") {
      error = "Password cannot be the word password";
    }
    return error;
}

import { useQuery, gql } from "@apollo/client";

const TEAM_BY_ID = gql`
  query ($id: String) {
    team(id: $id) {
      name
      key
      activeCycle {
        id
      }
      platforms {
        id
        name
      }
      assignees {
        id
        name
        email
      }
      cycles {
        id
        number
        startsAt
        endsAt
      }
    }
  }
`;
export default function useTeamCycleQuery(id) {
  return useQuery(TEAM_BY_ID, { variables: { id }, skip: !id });
}

import IssueStatusTable from "../IssueStatusTable/IssueStatusTable";

function TicketCountByStatusTable({ ...props }) {
  return (
    <IssueStatusTable
      title="Ticket Count by Status"
      aggregation="count"
      {...props}
    />
  );
}

export default TicketCountByStatusTable;

import { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";

import { StatusCategory, StatusCategoryOrder } from "../../constants/statuses";

import Pill from "../../components/Pill/Pill";

const WORKFLOW_STATES_QUERY = gql`
  query cycleIssues($cycleId: String!) {
    cycle(id: $cycleId) {
      id
      team {
        workflowstates {
          id
          name
          color
          stateType
          position
        }
      }
    }
  }
`;

export const useWorkflowStates = (cycleId) => {
  const { data, loading, error } = useQuery(WORKFLOW_STATES_QUERY, {
    variables: {
      cycleId,
    },
    skip: !cycleId,
  });

  const workflowStatesById = useMemo(() => {
    if (!data) return {};

    const workflowStatesById = {};

    data.cycle.team.workflowstates.forEach(
      ({ id, name, color, stateType, position }) => {
        workflowStatesById[id] = {
          id,
          name,
          color,
          stateType,
          position,
          Pill: <Pill color={color}>{name}</Pill>,
        };
      }
    );

    return workflowStatesById;
  }, [data]);

  return {
    loading,
    error,
    workflowStatesById,
  };
};

export const useWorkflowTable = (cycleId) => {
  const { workflowStatesById } = useWorkflowStates(cycleId);

  const { stateColumns, workflowStatesTemplate } = useMemo(() => {
    const stateColumnsById = {};
    const order = [];
    const workflowStatesTemplate = {
      total: 0,
    };

    const workflowStates = Object.values(workflowStatesById);

    workflowStates
      .filter((state) => state.stateType !== StatusCategory.BACKLOG)
      .forEach(({ id, Pill, stateType, position }) => {
        stateColumnsById[id] = {
          id,
          children: Pill,
          textAlign: "right",
        };

        order.push({
          id,
          stateType,
          position,
        });

        workflowStatesTemplate[id] = 0;
      });

    // Sort the columns by the position from the BE
    const stateColumns = order
      .sort(sortWorkflowStates)
      .map(({ id }) => stateColumnsById[id]);

    return {
      stateColumns,
      workflowStatesTemplate,
    };
  }, [workflowStatesById]);

  return {
    stateColumns,
    workflowStatesTemplate,
  };
};

function sortWorkflowStates(a, b) {
  // map state to order.
  const stateOrderA = StatusCategoryOrder.indexOf(a.stateType);
  const stateOrderB = StatusCategoryOrder.indexOf(b.stateType);

  // Sort by state type order first
  if (stateOrderA < stateOrderB) return -1;
  if (stateOrderA > stateOrderB) return 1;

  // Sort by position
  if (a.position < b.position) return -1;
  if (a.position > b.position) return 1;

  return 0;
}

import { Link, Redirect, Route, Router, Switch } from "react-router-dom";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import History from "../history";
import { Routes } from "../constants/routes";

import Nav from "./Nav/Nav";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import ActiveReport from "./ActiveReport/ActiveReport";
import SprintEndReport from "./SprintEndReport/SprintEndReport";
import Login from "./Login/Login";

import TeamCycleResolver from "./TeamCycleResolver/TeamCycleResolver";
import Message from "./Message/Message";

import styles from "./App.module.scss";

// Extend utc and timezone to set a global timezone for all dates.
// Dates must use dayjs.tz() to parse into this default timezone.
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");

function App() {
  return (
    <Router history={History}>
      <Nav />

      <div className={styles.PageWrapper}>
        <Switch>
          <PrivateRoute path={Routes.HOME} exact component={ActiveReport} />

          <PrivateRoute
            path={Routes.ACTIVE_REPORT} // Optional cycle number, will default to active cycle if any.
            render={() => (
              <TeamCycleResolver
                component={ActiveReport}
                error={
                  <Message>
                    Team does not exist. <Link to={Routes.HOME}>Go back.</Link>
                  </Message>
                }
              />
            )}
          />

          <PrivateRoute
            path={Routes.SPRINT_END_REPORT} // Optional cycle number, will default to active cycle if any.
            render={() => (
              <TeamCycleResolver
                component={SprintEndReport}
                error={
                  <Message>
                    Team does not exist. <Link to={Routes.HOME}>Go back.</Link>
                  </Message>
                }
              />
            )}
          />

          <Route path={Routes.LOGIN} exact component={Login} />

          {/* Redirect to root when Route does not match any of the above - acts as 404 */}
          <Redirect to={Routes.HOME} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;

import React, { useMemo } from "react";

import useCycleBlockedIssuesQuery from "../../graphql/hooks/useCycleBlockedIssuesQuery";
import { useWorkflowStates } from "../../graphql/hooks/useWorkflowStatesQuery";
import { buildLinearLink } from "../../utils/buildLinearLink";
import getIssueType from "../../utils/getIssueType";

import Container from "../Container/Container";
import Table from "../Table/Table";

const COLUMNS = [
  {
    id: "Platform",
    children: "Platform",
  },
  {
    id: "ID",
    children: "ID",
  },
  {
    id: "Summary",
    children: "Summary",
  },
  {
    id: "Type",
    children: "Type",
  },
  {
    id: "Epic",
    children: "Epic",
  },
  {
    id: "Status",
    children: "Status",
  },
  {
    id: "Points",
    children: "Points",
    textAlign: "right",
  },
];

const SprintEndBlockedTable = ({ cycleId, filters, ...props }) => {
  const { data, loading, error } = useCycleBlockedIssuesQuery(cycleId, filters);
  const { workflowStatesById } = useWorkflowStates(cycleId);

  const rows = useMemo(() => {
    if (loading || !data) return null;

    const rows = data.blockedIssues
      .map((issue) => {
        const platformsCell = issue.platforms.map((p) => p.name).join(" ");

        const linkCell = buildLinearLink(data.cycle.team.key, issue.number);

        return [
          platformsCell || null,
          linkCell,
          issue.title,
          getIssueType(issue.labels)|| null,
          issue.project?.name,
          workflowStatesById[issue.state.id]?.Pill,
          issue.estimate || 0,
        ];
      })
      .sort((a, b) => {
        const platformCell = (row) => row[0] || "";
        const stateCell = (row) => row[5].props.children;
        if (platformCell(a) !== platformCell(b)) {
          return platformCell(a).localeCompare(platformCell(b));
        } else {
          return stateCell(a).localeCompare(stateCell(b));
        }
      });

    return rows;
  }, [data, loading, workflowStatesById]);

  return (
    <Container
      title="Blocked"
      titleCount={rows?.length}
      error={error}
      loading={loading}
      empty={!rows?.length}
      emptyMessage="No blocked issues in this cycle"
      {...props}
    >
      {rows && <Table columns={COLUMNS} rows={rows} />}
    </Container>
  );
};

export default SprintEndBlockedTable;

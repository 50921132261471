import React from "react";
import classnames from "classnames";

import styles from "./Spinner.module.scss";

const Spinner = ({ className, ...props }) => {
  return (
    <span
      className={classnames(className, styles.Spinner)}
      aria-label="Loading"
      {...props}
    />
  );
};

export default Spinner;

import React, { useMemo } from "react";

import { Status } from "../../constants/statuses";
import useCycleIssuesQuery from "../../graphql/hooks/useCycleIssuesQuery";
import useTeamCycleQuery from "../../graphql/hooks/useTeamCycleQuery";

import Container from "../Container/Container";
import Table from "../Table/Table";

import styles from "./Velocity.module.scss"

const Velocity = ({ teamId, cycleId, filters }) => {
  const { data: cycleData } = useTeamCycleQuery(teamId);
  const { data, loading, error } = useCycleIssuesQuery(cycleId, filters);

  const { rows, columns, totalAchieved } = useMemo(() => {
    if (loading || !data) return {};

    const columns = [
      { id: "1", children: "Platform" },
      { id: "2", children: "Committed" },
      { id: "3", children: "Achieved" },
      { id: "4", children: "Not Done" },
    ];
    const rows = [];

    const pointsPerLabel = {};
    for (const issue of data.cycle.issues) {
      for (const { name: labelName } of issue.labels) {
        if (!pointsPerLabel[labelName]) {
          pointsPerLabel[labelName] = { committed: 0, achieved: 0 };
        }
        pointsPerLabel[labelName].committed += issue.estimate;
        if (issue.state.name === Status.DONE) {
          pointsPerLabel[labelName].achieved += issue.estimate;
        }
      }
    }

    const platformsToList = (
      filters.platforms.length > 0
        ? filters.platforms
        : cycleData?.team?.platforms?.map(({ name }) => name) || []
    ).sort();

    const achievedString = (achieved, committed) =>
      achieved === 0
        ? "0"
        : `${achieved} (${Math.round((achieved / committed) * 100)}%)`;

    let totalCommitted = 0;
    let totalAchieved = 0;
    let totalNotDone = 0;
    for (const platform of platformsToList) {
      const { committed = 0, achieved = 0 } = pointsPerLabel[platform] || {};
      const notDone = committed - achieved;
      totalCommitted += committed;
      totalAchieved += achieved;
      totalNotDone += notDone;
      rows.push([
        platform,
        committed,
        achievedString(achieved, committed),
        notDone,
      ]);
    }
    rows.push([
      "Total",
      totalCommitted,
      achievedString(totalAchieved, totalCommitted),
      totalNotDone,
    ]);

    return {
      rows,
      columns,
      totalAchieved,
    };
  }, [data, cycleData, loading, filters.platforms]);

  return (
    <Container
      title="Velocity"
      titleCount={totalAchieved}
      error={error}
      loading={loading}
      empty={!rows?.length}
      emptyMessage="No issues in this cycle"
    >
      {rows && <Table className={styles.Table} columns={columns} rows={rows} />}
    </Container>
  );
};

export default Velocity;

import api, { setAuthToken } from "./api.service";

const subscribers = new Set();

function watchUser(callback) {
  subscribers.add(callback);
  return () => {
    subscribers.delete(callback);
  };
}
function notifySubscribers(newUser) {
  subscribers.forEach(callback => callback(newUser));
}

// Logout
function logout() {
  localStorage.removeItem("auth_token");
  // Clear the API auth token.
  setAuthToken(null);
  notifySubscribers(null);
}

// Get Token
function isAuthenticated() {
  return !!localStorage.getItem(`auth_token`);
}

// Set Token
function setToken(token) {
  if (token) {
    localStorage.setItem("auth_token", token);
    setAuthToken(token);
  }
}

// Login
async function login(email, password) {
  let response;

  try {
    response = await api().post("/auth/login", { email, password });
  } catch (err) {
    console.error(err);
    throw err;
  }

  setToken(response.data.auth_token);
  notifySubscribers(response);

  return response;
}

const AuthService = {
  watchUser,
  login,
  isAuthenticated,
  logout,
};

export default AuthService;

import dateLoop from "./dateLoop";

export default function workdayCount(startDate, endDate) {
  let count = 0;

  // Count all the days between, skipping Sat/Sun
  dateLoop(
    startDate,
    endDate,
    () => {
      count++;
    },
    {
      skipWeekendDays: true,
    }
  );

  return count;
}

// eslint-disable-next-line no-process-env
const {
  REACT_APP_API_URL,
  REACT_APP_API_GRAPHQL_URL,
  REACT_APP_API_VERSION,
  REACT_APP_SYNC_DURATION_MINUTES,
} = process.env;

export const API_URL = REACT_APP_API_URL;
export const API_GRAPHQL_URL = REACT_APP_API_GRAPHQL_URL;
export const API_VERSION = REACT_APP_API_VERSION;
export const SYNC_DURATION_MINUTES = REACT_APP_SYNC_DURATION_MINUTES;
export const POLL_INTERVAL = (SYNC_DURATION_MINUTES * 60 * 1000) / 5;

// Status values enum.
export const Status = {
  TODO: "Todo",
  IN_PROGRESS: "In Progress",
  BLOCKED: "Blocked",
  IN_REVIEW: "In Review",
  PENDING_QA: "Pending QA",
  PENDING_PRODUCT: "Pending Prod",
  DONE: "Done",
};

// Human readable status text.
export const StatusLabel = {
  [Status.TODO]: "Todo",
  [Status.IN_PROGRESS]: "In Progress",
  [Status.BLOCKED]: "Blocked",
  [Status.IN_REVIEW]: "In Review",
  [Status.PENDING_QA]: "Pending QA",
  [Status.PENDING_PRODUCT]: "Pending Prod",
  [Status.DONE]: "Done",
};

// Colors to identify status.
export const StatusColors = {
  [Status.TODO]: "#EAEAEA",
  [Status.IN_PROGRESS]: "#F4AE3D",
  [Status.BLOCKED]: "#ED6140",
  [Status.IN_REVIEW]: "#6158BA",
  [Status.PENDING_QA]: "#50B6D5",
  [Status.PENDING_PRODUCT]: "#2368F6",
  [Status.DONE]: "#419667",
};

export const StatusColorsByLabel = {
  //standard Linear labels
  "To Do": "#EAEAEA",
  "In Progress": "#F4AE3D",
  Blocked: "#ED6140",
  "Code Review": "#6158BA",
  "Pending QA": "#50B6D5",
  "Pending Prod": "#2368F6",
  Done: "#419667",
  //Known exceptions
  Todo: "#EAEAEA",
  "In Code Review": "#6158BA",
  "In Review": "#6158BA",
};

// Status options for use in Select inputs.
export const StatusOptions = Object.keys(Status).map((k) => {
  return {
    value: Status[k],
    label: StatusLabel[Status[k]],
  };
});

export const StatusCategory = {
  BACKLOG: "backlog",
  UNSTARTED: "unstarted",
  STARTED: "started",
  COMPLETED: "completed",
  CANCELED: "canceled",
};

// Linear defined workflow category order.
export const StatusCategoryOrder = [
  StatusCategory.BACKLOG,
  StatusCategory.UNSTARTED,
  StatusCategory.STARTED,
  StatusCategory.COMPLETED,
  StatusCategory.CANCELED,
];

// Segments of states.
export const todoStates = [
  Status.TODO,
  Status.IN_PROGRESS,
  Status.BLOCKED,
  Status.IN_REVIEW,
];
export const developmentStates = [
  Status.TODO,
  Status.IN_PROGRESS,
  Status.IN_REVIEW,
];
export const reviewStates = [Status.PENDING_QA, Status.PENDING_PRODUCT];
export const completeStates = [Status.DONE];

import React from "react";
import classnames from "classnames";

import styles from "../Fieldset/Fieldset.module.scss";

function Fieldset({
  className,
  text,
  value,
  type,
  placeholder,
  onChange,
  ...props
}) {
  const FieldsetInputTag = type === 'select' ? 'select': 'input';

  return (
    <label
      className={classnames(
        className,
        styles.Fieldset
      )}
    >
      <span className={styles.Label}>{text}</span>
      <FieldsetInputTag
        className={classnames(
          styles.Input,
          type === 'select' && styles.Select,
        )}
        value={value}
        type={type !== 'select' ? type: undefined}
        placeholder={placeholder}
        onChange={onChange}
        {...props}
      />
    </label>
  );
}

export default Fieldset;

import React from "react";

import Avatar from "../Avatar/Avatar";

const NBSP = '\xa0';

export function removeFueledFromName(name) {
  if (!name) return;

  // Catches all kinds of separators found in names
  const separators = '-|=~,;.';
  const regEx = new RegExp(`[${separators}].*Fueled`, 'g');

  return name.replace(regEx, '').trim();
}

export function createShortName(_name, removeFueled) {
  if (!_name) return;

  const name = removeFueled ? removeFueledFromName(_name) : _name;

  let [shortName, ...lastName] = name.split(" ");

  for (const lastNamePart of lastName) {
    shortName += `${NBSP}${lastNamePart[0]}`;
  }

  return shortName;
}

const Assignee = ({
  assignee,
  avatarSize = "small",
  noAvatar,
}) => {
  if (!assignee) return null;

  const { avatarUrl } = assignee;
  const name = removeFueledFromName(assignee.name);

  if (!avatarUrl && !name) return null;

  if (avatarUrl && !noAvatar) {
    return <Avatar src={avatarUrl} alt={name} size={avatarSize} />;
  }

  return createShortName(name);
};

export default Assignee;

import { Status, StatusColors, StatusLabel } from "../constants/statuses";

export const mapStateToProgress =
  (estimatesMap, workflowStatesById) => (state) => {
    const color = Object.values(workflowStatesById).find(
      (s) => s.name === state
    )?.color;
    return {
      id: state,
      value: estimatesMap[StatusLabel[state]],
      label: StatusLabel[state],
      color: color || StatusColors[Status.TODO],
    };
  };
